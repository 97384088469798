@import "../variables.scss";

#EntityAdd,
#EntityEdit,
#ChannelAdd,
#ChannelEdit,
#LabelAdd,
#LabelForm,
#LabelEdit,
#IntegrationForm,
#EntityPicture,
#AddressAdd,
#DeletePicture,
#AddGroup,
#IntegrationAdminForm,
#IntegrationAdminAdd,
#IntegrationAdminEdit,
#IntegrationNode,
#ModuleAdd,
#ModuleEdit,
#ModuleForm,
#ContractAdd,
#ContractEdit,
#ContractForm {

  .ui.form .field .ui.input input,
  .ui.multiple.search.dropdown,
  .ui.form textarea,
  .ui.form textarea:focus {
    border-radius: 8px;
  }

  .ui.form .field .ui.input input,
  .ui.multiple.search.dropdown {
    height: 48px;
  }

  .ui.form .field > label,
  .ui.form .input-component > label,
  .ui.form .input-component > span {
    font-size: 1.143rem;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .ui.form .input-component input,
  .ui.form .input-component textarea {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .ui.form .field {
    margin: 0 0 24px;
  }

  & > .error-warning {
    margin-top: 0.8rem;
    color: $main-red;

    & > .icon {
      margin-right: 0.5rem;
      font-weight: 900;
    }
  }

  .ui.form .field.last-item {
    margin: 0;
  }

  .field {
    font-size: 16px;

    .circle.large.icon {
      color: rgba(0, 0, 0, 0.24);
      vertical-align: top;
      font-size: 24px;
      font-weight: 300;
    }
  }

  .error-warning {
    margin-top: 6px;
    margin-bottom: 12px;
    color: $main-red;

    & > .icon {
      margin-right: 0.5rem;
      font-weight: 900;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
  }

  .circle.large.icon {
    font-size: 24px;
  }
}

#AddressAdd {
  .ui.form .field > label,
  .ui.form .input-component > label {
    font-weight: 700;
    font-size: 18px;
  }

  .ui.form .field.phone-field {
    margin-bottom: -44px;
  }

  .phone-dropdown {
    width: auto;
    white-space: nowrap;

    &.red {
      color: #BF2600
    }
  }

  .ui.form .field .ui.input input,
  .ui.form .input-component input {
    border-radius: 200px;
    height: 40px;
  }

  .ui.scrolling.dropdown {
    margin: 8px 8px 8px 0;
    height: 40px;
    border-radius: 200px;
    padding: 7px 14px;
  }

  .error-warning {
    margin-top: -6px;
  }
}

#SelectUser,
#SelectGroup,
#ConciergeAdd, {
  .error-warning {
    margin-top: 0.8rem;
    margin-bottom: 6px;
    color: $main-red;

    & > .icon {
      margin-right: 0.5rem;
      font-weight: 900;
    }
  }
}
