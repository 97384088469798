#MessageEntities {
    .tag {
        border: 1px solid #D6D6D6;
        box-sizing: border-box;
        border-radius: 200px;
        padding: 6px 8px;
    }
    .mobile-search {
        .input {
            width: 100%;
        }
    }
    .mobile-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 5em;
        width: 5em;
        box-sizing: border-box;
        border-radius: 4px;
    }
}
