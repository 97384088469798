#calendarView {
  align-self: center;

  &.ui.table {
    border: none;
    width: 280px !important;

    &.with-border {
      border: 1px solid rgb(233, 236, 239);
    }

    &.with-padding {
      padding: 10px;
      width: 300px !important;
    }

    thead {

      th {
        background: none;
        border-bottom: none;
      }

      th.header {
        padding: 0 !important;
      }

      tr.weekdays th {
        padding: 0 !important;
        width: 40px;
        height: 40px;
        text-align: center;
      }

      .title {
        width: 200px;
        font-weight: 700;
        
        &:hover {
          background: none;
          cursor: default;
        }
      }

      .previous-month,
      .next-month {
        width: 38px;
        height: 40px;
        padding: 0 !important;
        border-radius: 20px;

        > .icon {
          margin: 0;
        }
      }
    }

    tbody tr td {
      padding: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
    }

    tr td {
      border-top: none;
    }
  }

  &.ui.celled.table tr td, &.ui.celled.table tr th {
    border-left: none;
  }
  
  .ui.menu {
    box-shadow: none;
    position: sticky;

    > .item {
      display: flex;
    }
    
    .item::before {
      background: none;
    }
  }
  
  .ui.menu, .ui.segment {
    border: none;
  }
}

#DatePicker {
  
  &.ui.modal:not(.toast) {
    width: 342px;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .time-selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 280px;

    .ui.selection {
      padding: 16px;
      border-radius: 8px;
  
      .icon {
        margin: 0 0 0 8px;
        font-size: 16px;
      }
  
      &.visible {
        border-radius: 0 0 8px 8px !important;
        border-color: rgba(34,36,38,.35);
      }
  
      .menu.transition.visible {
        border-radius: 8px 8px 0 0;
        border-color: rgba(34,36,38,.35);

        &:hover {
          border-color: rgba(34,36,38,.35);
        }
      }
    }
  }
}