html,
body {
  background-color: #FFFFFF !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  min-width: unset !important;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, .25);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .1);
    border-radius: 0;
  }
}

body.paddingOnModalDimmer>.dimmer {
  padding: 3rem 0;
}

blockquote {
  border-left: 2px solid #ddd;
  color: #999;
  font-style: italic;
  margin: 12px 0 40px 0;
  padding: 6px 0 5px 18px;
}

#root {
  overflow-y: auto;
  margin-top: 64px;
  height: calc(100% - 64px);

  @media only screen and (min-width: 768px) {
    &.withBanner {
      margin-top: 119px;
      height: calc(100% - 119px);
    }
  }


  >.pushable {
    transform: initial;

    .pusher {
      padding-bottom: 3.3em;

      >.container {
        margin-top: 80px;
      }
    }

    .pusher.md {
      padding-bottom: 8.2em;
    }

    .ui.sidebar {
      position: fixed;
    }
  }
}

#Home {
  overflow: visible;
}

.code-checker .ui.input input {
  text-align: center;
  color: #212529;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 5px;
}

.entity-drop-down {
  display: flex;
  align-items: center;

  div.menu.transition.visible {
    width: calc(100% + 80px) !important;
  }
}

.entity-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 45vw !important;
  max-width: 600px !important;
}

.entity-drop-down-mobile {
  display: flex;
  align-items: center;

  div.menu.transition.visible {
    width: calc(100% + 90px) !important;
  }
}

.ui.modals {
  flex-direction: column;

  >.ui.modal.transition {
    margin: auto !important;
    top: unset;
    left: unset !important;
    position: relative !important;


    >.content {
      background-color: #FFFFFF;
      padding: 16px;

      img {
        max-width: 100%;
      }

      .media-image>img {
        max-height: calc(100vh - 9rem);
      }
    }

    &.toast {
      position: absolute !important;
    }
  }

  /* Workaround for semantic close button going over media */
  @media (max-width: 991px) {
    .mediaItem {
      padding-top: 2.5rem;
    }
  }
}

.ui.modal {
  >.header:not(.ui) {
    font-size: 1.28571429em;
    line-height: 1em;
  }

  >.actions {
    background: #fff !important;
    padding: 1rem 0.7rem !important;

    >.button {
      margin-left: 0.2em;
    }
  }
}

i.icon.eye.link:before {
  content: "\f06e";
}

.ui.dimmer.modals.unnoticed {
  width: 1px;
  height: 1px;
}

.ui.circular.floating {
  >.delete {
    display: table;
    margin: auto;
    font-size: larger;
  }
}

.ui.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.ui.vertical.menu .menu .item {
  padding: 0.92857143em 1.14285714em;
}

.dimmer.none {
  background: none !important;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.75);
}

.ui.visible.popup.transition {
  &.custom-popup-top-left {
    transform: translate(-70%, 0);
    min-height: fit-content;

    &::before {
      top: -4px;
      transform: translate(13rem, 0) rotate(225deg);
    }

    &.popup-desktop {
      transform: unset;

      &::before {
        transform: rotate(225deg);
      }
    }
  }

  &.custom-popup-right-center.popup-desktop {
    transform: unset;

    &::before {
      top: calc(50% - 2px);
      left: -4px;
      transform: rotate(135deg);
    }
  }
}

.ui.form textarea {
  font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

input,
textarea,
select,
button {
  font-size: 14px;
}

.ui.button {
  padding: 0.78571429em 1.15em 0.78571429em;
}

.ui.button.cleanButton {
  border: 1px solid #e7e7e7;
  color: #0078f0;
  background-color: white;
}

.recipientsButton {
  padding: 0 0 0 !important;
}

.ui.inactive.button,
.ui.inactive.buttons .button {
  background-color: #adb5bd;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.ql-tooltip {
  z-index: 1;
}

.ql-container {
  font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

.ql-editor {
  min-height: 14px;

  // fit image to container
  img {
    max-width: 100%;
  }

  .media-image>img {
    max-height: calc(100vh - 9rem);
  }
}

.slick-dots.slick-dots-clsp {
  bottom: 50px;
  margin-bottom: 35px;
}

button.slick-arrow.slick-next {
  right: 25px;
}

button.slick-arrow.slick-prev {
  left: 25px;
  z-index: 1;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  opacity: 0.75;
  color: #fff;
}

.dimmer.none {
  background: none !important;
}

.ql-editor {
  padding: 14px 24px !important;
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  .ql-editor {
    padding: 12px 12px !important;
  }
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  color: #cecece !important;
}

#toolbar>*,
#toolbar>*>i,
#toolbar>*>span>i {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 300 !important;
}

.ql-toolbar.ql-snow {
  position: absolute;
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  bottom: 51px;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  padding: 19px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.16) !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-snow,
.content {

  .ql-editor p,
  .ql-editor ol,
  .ql-editor ul,
  .ql-editor pre,
  .ql-editor blockquote,
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
    margin: 0em 0em 0.5em 0em;
    line-height: 1.38;
  }
}

.wordWrapped {
  word-wrap: break-word !important;
  word-break: break-word;
}

.textLimit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.buttonHover:hover {
  background-color: #eeeeee !important;
}

.buttonHover.pointer:hover {
  background-color: #FFFFFF !important;
}

.lighten-on-hover:hover {
  opacity: 0.8;
}

.label.title {
  font-size: 34px !important;
}

.label.subtitle {
  font-size: 18px !important;
  color: #868e96;
  text-align: left;
}

.noMargin {
  margin: 0 !important;
}

.noPadding {
  padding: 0 !important;

  .ql-editor {
    padding: 0 !important;
  }
}

.noBorder {
  border: none !important;
}

.bold {
  font-weight: bold !important;
}

.errored {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;

  .public-DraftEditorPlaceholder-inner {
    color: #9f3a38 !important;
  }
}

.weekendCalendar {
  color: #9696a0;
  height: 100px;
  white-space: nowrap;
}

.noneCalendar {
  white-space: nowrap;
  height: 100px;
}

.calendarEvent {
  overflow: hidden;
  text-overflow: ellipsis;
}

.horizontallySpacedItems {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > .message-content {
    width: calc(30vw);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #999999;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
}

.ui.list [class*="middle aligned"],
.ui.list[class*="middle aligned"] .content,
.ui.list[class*="middle aligned"] .semantic_icon {
  vertical-align: baseline !important;
}

.pointer {
  cursor: pointer;
}

.displayNone {
  display: none !important;
}

.gridItem {
  height: 48px;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    justify-content: left;
    padding: 0px 8px;
  }
}

.gridItemHeader {
  position: sticky;
  position: -webkit-sticky;
  background: #FFFFFF;
  top: 0;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  height: 66px;
}

.defaultClspColor {
  color: #0080ff !important;
}

.item.organizationItem.vertical {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 16px;
}

.centeredItems {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.hoverReveal {
  position: relative;
}

.hoverReveal>div:nth-child(2) {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  opacity: 0;
}

.hoverReveal>div:nth-child(2):hover {
  opacity: 1;
}

.clsp-square {
  position: relative;
  width: 50%;
}

.clsp-square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.clsp-square-content {
  position: absolute !important;
  width: 100%;
  height: 100%;

  .slick-list {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }
}

.clsp-video>div {
  width: 100%;
  height: 100%;

  .rh5v-DefaultPlayer_component {
    width: 100%;
    height: 100%;
  }
}

.rh5v-Volume_component svg {
  height: 28px;
  width: 28px;
}

.clsp-circular .cropper-view-box {
  border-radius: 50%;
}

.react-thumbnail-generator {
  height: 100%;
  display: flex;
}

div.circular .react-thumbnail-generator img {
  border-radius: 4px;
}

.clsp-content p,
.clsp-content ol,
.clsp-content ul,
.clsp-content pre,
.clsp-content blockquote,
.clsp-content h1,
.clsp-content h2,
.clsp-content h3,
.clsp-content h4,
.clsp-content h5,
.clsp-content h6 {
  margin: 0em 0em 0.5em 0em;
}

.clsp-content pre {
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
  white-space: pre-wrap;
}

.clsp-content {
  white-space: pre-wrap;
}

.icon.pointer:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

.item.hover:hover {
  color: black !important;
}

.coverImage {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.momentImage {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.ui.multiple.search.dropdown>.text {
  display: -moz-box;
  display: -webkit-box;
}

.ui.selection.dropdown>.search.icon {
  cursor: default;
  pointer-events: none;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: -1.5px;
  right: 0;
  margin: 0;
  height: 100%;
  width: 2.67142857em;
  opacity: 0.5;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.ui.multiple.selection.search.dropdown {
  &.icon-left {
    >.at.icon {
      cursor: default;
      pointer-events: none;
      position: absolute;
      line-height: 1;
      text-align: center;
      top: -1px;
      left: 0;
      margin: 0;
      padding: .91666667em .91666667em .91666667em 0.5em;
      height: 100%;
      width: 2.67142857em;
      opacity: 0.5;
      border-radius: 0 0.28571429rem 0.28571429rem 0;
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
    }

    >input.search {
      margin-top: 0;
      height: 100%;
    }

    >input.search,
    >.text,
    >.label {
      margin-left: 2.25em;
    }

    >.default.text {
      margin-left: 2.4rem;
    }

    >.label+.label {
      margin-left: 0;
    }
  }

  &.custom-label {
    height: 40px;
    padding: 0 0.5em;
    align-items: center;

    .default.text {
      padding-top: 0.3rem;
    }

    a {
      margin: 0.3rem 0.385rem;
      padding: 0 0.77rem 0 0;
      height: 2.156rem;
      background-color: transparent;
      border-radius: 1.078rem;
      border: 1px solid #E8E8E8;
      box-shadow: none;
      font-size: 0.857rem;
      color: #939393;
      font-weight: 450;
      line-height: 100%;
      cursor: unset;
      align-self: flex-start;

      img {
        height: 2rem !important;
        width: 2rem !important;
        margin-right: .3rem;
      }

      i.delete.icon {
        color: #A5A5A5;
        opacity: 1;
        height: 0.65rem;
        width: 0.95rem;
        margin-left: 0.72842rem;
        font-size: 0.86rem;

        &::before {
          font-size: 1.09263rem;
          font-weight: 700;
          content: "\f057";
        }
      }
    }
  }

  &.round-corners {
    border-radius: 1.5rem;

    ::-webkit-scrollbar-track {
      border-radius: 0 0 0.5em 0.5em;
    }

    .menu.transition.visible {
      min-width: calc(100% + 2px) !important;
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }

  &.small-suggestion-avatars {
    .menu.transition.visible {
      .avatar {
        width: 1.2em;
        height: 1.2em;
        margin-top: auto;
      }
    }
  }
}

.ui.input.round-input {
  input {
    border-radius: 1.5rem;
  }
}

.ui.input.adjust-inner-input {
  input {
    width: 100%;
  }
}

.custom-label {
  display: flex;
  flex-wrap: wrap;

  .default.text {
    padding-top: 0.40rem;
  }

  .ui.label {
    margin: 0.4rem 0.51rem;
    padding: 0 1.123rem 0 0;
    height: 2.858rem;
    background-color: transparent;
    border-radius: 1.429rem;
    border: 1px solid #E8E8E8;
    box-shadow: none;
    font-size: 1.136rem;
    color: #939393;
    font-weight: 450;
    line-height: 100%;
    cursor: unset;
    display: flex;
    align-items: center;
    width: fit-content;

    img {
      height: 2.653rem !important;
      width: 2.653rem !important;
      margin-right: .398rem;
    }

    i.delete.icon {
      color: #A5A5A5;
      opacity: 1;
      height: 0.862rem;
      width: 1.259rem;
      margin-left: 0.965rem;
      font-size: 1.254rem;

      &::before {
        font-size: 1.448rem;
        font-weight: 700;
        content: "\f057";
      }
    }
  }


  >span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.23rem;
    align-self: center;
  }

  >button {
    background: none;
    border: none;
    color: #0038F5;
    font-weight: 700;
    font-size: 1.23rem;
    cursor: pointer;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
}

.ui.horizontal.divider:before {
  width: 1em;
}

.ui.segment.label-content {
  transform: translateY(14px);
  margin-bottom: calc(28px + 14px);
}

.container.new>.ui.button,
.add-button.new {
  padding: 0.9rem 1.5rem;
  border-radius: 40px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.16);
  transform: translateY(-40px);
}

.ui.section {

  &~.add-button,
  &~.container>.ui.button {
    transform: translateY(-28px);
    position: absolute;
    right: 0px;
  }

}

.ui.segment.new {
  border: none;

  & input {
    border-radius: 0.5rem;
  }

  & .field-group {
    display: flex;
    flex-wrap: nowrap;

    &>.field {
      width: 50%;

      &>.dropdown {
        min-width: 0px;
      }
    }
  }

  & span.secondary {
    border-radius: 100%;
  }

  &.row>div {
    margin-bottom: calc(20px + 12px);

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &:not(.row):not(:not(span.checkbox))>div {
    &:nth-child(n + 4) {
      margin-top: 8px;
    }
  }

  & span.checkbox {
    padding: 12px 16px;
    border-radius: 8px;

    &.checked {
      background-color: #F2F7FF;
    }

    &~.fields {
      margin-left: 24px;
      margin-top: 23px;
    }
  }

  &>a.large.label {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);

    &>span>i {
      font-weight: 300;
    }
  }

  &>div.large.label {

    &>i {
      font-weight: 300;
    }
  }

  &:not(.org-info):not(.row) {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    &>div:not(.large.label) {
      width: calc(90% / 3);
      min-width: max-content;
      margin-right: calc(10% / 3);

      &:not(.label.large):nth-child(n + 4) {
        margin-top: 32px;
      }

      @media screen and (max-width: 1120px) {
        & {
          width: 45%;
          margin-right: 5%;

          &:nth-child(n + 3) {
            margin-top: 10px;
          }
        }
      }

      @media screen and (max-width: 926px) {
        & {
          width: 100%;
          margin-right: 0px;

          &:nth-child(n + 2) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.ui.menu,
.ui.segment,
.ui.table {
  border: 1px solid rgba(34, 36, 38, 0.1);
  box-shadow: none;
}

.ui.celled.list>.item,
.ui.celled.list>.list {
  border-top: 1px solid rgba(34, 36, 38, 0.1);
}

.ui[class*="top fixed"].menu {
  height: 55px;
}

.ui.segment>.ui.list {
  .item.active {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .item:first-child {
    border-top: none;
  }
}

.ui.selection.list .list>.item,
.ui.selection.list>.item {
  color: rgba(0, 0, 0, 0.8);
}

.ui.vertical.menu {
  box-shadow: none;

  .item>i.icon {
    float: left;
    margin: 0 0.5em 0 0;
  }
}

.ui.icon.input>i.icon:not(.link) {
  pointer-events: initial;
}

.ui.inverted.menu {
  background: #333;
}

.ui.modal>.close {
  font-weight: bold;
}

.ui.modal>.close.inside {
  cursor: pointer;
  position: absolute;
  top: 1.0535rem;
  right: 1rem;
  color: rgba(0, 0, 0, 0.87);
  z-index: 1;
  opacity: 0.8;
  font-size: 1.25em;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.25rem 0 0 0;
}

@media only screen and (max-width: 767px) {
  .ui.modal>.actions {
    padding: 1rem 1rem !important;
  }
}

.ui.loaderbar {
  width: 100%;
  position: fixed;
  z-index: 102;

  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    left: 0%;
    right: 100%;
    animation: loading 2s ease infinite;
  }

  &.active:after {
    background-color: #888;
  }
}

.ui.multiple.dropdown {
  display: flex;
  flex-wrap: wrap;

  &>.label {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.5833em 0.833em 0.5833em 0.5em;
  }
}

.ui.dropdown .menu>.item>.image,
.ui.dropdown .menu>.item>img {
  display: inline-block;
  vertical-align: top;
  margin-top: -0.5em;
  width: 28px;
  margin-bottom: -0.5em;
  max-height: 2em;
}

.ui.dropdown.menu-max-height .menu {
  max-height: 10rem;
}

.ui.label.uppercase {
  text-transform: uppercase;
}

.ui.labeled.button:not(.icon) {
  display: -webkit-inline-box;

  >.icon {
    float: left;
  }
}

.ui.progress {
  .bar {
    height: 13px;
  }

  &.red .bar {
    background: #E75353;
  }

  &.yellow .bar {
    background: #F2C617;
  }

  &.green .bar {
    background: #74B816;
  }
}

.ui.label>img {
  width: 2.1666em !important;
  vertical-align: middle;
  height: 2.1666em !important;
}

@keyframes loading {
  0% {
    left: 0;
    right: 100%;
  }

  50% {
    left: 0;
    right: 0;
  }

  100% {
    left: 100%;
    right: 0;
  }
}

.helpButton {
  display: flex !important;
  align-items: center;
  color: #fff !important;
  border-radius: 999em !important;
  position: fixed;
  bottom: 10px;
  right: 20px;
  padding: 0.8em 1.2em !important;
}

// Line height -> Line Spacing
.ql-snow .ql-line-height.ql-picker {
  min-width: 60px;

  .ql-picker-item[data-value="1.2"]::before,
  .ql-picker-label[data-value="1.2"]::before {
    content: '1.0';
  }

  .ql-picker-item[data-value="1.38"]::before,
  .ql-picker-label[data-value="1.38"]::before {
    content: '1.15';
  }

  .ql-picker-item[data-value="1.8"]::before,
  .ql-picker-label[data-value="1.8"]::before {
    content: '1.5';
  }

  .ql-picker-item[data-value="2.4"]::before,
  .ql-picker-label[data-value="2.4"]::before {
    content: '2.0';
  }
}

// Margin Bottom -> Paragraph Spacing
.ql-snow .ql-margin-bottom.ql-picker {
  min-width: 60px;

  .ql-picker-item[data-value="0pt"]::before,
  .ql-picker-label[data-value="0pt"]::before {
    content: '0pt';
  }

  .ql-picker-item[data-value="5pt"]::before,
  .ql-picker-label[data-value="5pt"]::before {
    content: '5pt';
  }

  .ql-picker-item[data-value="10pt"]::before,
  .ql-picker-label[data-value="10pt"]::before {
    content: '10pt';
  }

  .ql-picker-item[data-value="15pt"]::before,
  .ql-picker-label[data-value="15pt"]::before {
    content: '15pt';
  }

  .ql-picker-item[data-value="20pt"]::before,
  .ql-picker-label[data-value="20pt"]::before {
    content: '20pt';
  }
}

.lang-pt {

  // Headers
  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: "Comum";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "Título 1";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "Título 2";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "Título 3";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "Título 4";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "Título 5";
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "Título 6";
  }

  // Font
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: "Comum";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    content: "Pequeno";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    content: "Grande";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    content: "Enorme";
  }

  .ql-snow .ql-tooltip::before {
    content: "Ir para URL:";
    line-height: 26px;
    margin-right: 8px;
  }

  .ql-snow .ql-tooltip {
    margin-left: 110px;
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    border-right: 1px solid #ccc;
    content: "Alterar";
    margin-left: 16px;
    padding-right: 8px;
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    content: "Excluir";
    margin-left: 8px;
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "Salvar";
    padding-right: 0;
  }

  .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "Digite o link:";
  }

  .ql-snow .ql-tooltip[data-mode="link"] {
    margin-left: 130px;
  }
}

.lang-en {
  .ql-snow .ql-tooltip::before {
    content: "Visit URL:";
    line-height: 26px;
    margin-right: 8px;
  }

  .ql-snow .ql-tooltip {
    margin-left: 110px;
  }

  .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "Enter link:";
  }

  .ql-snow .ql-tooltip[data-mode="link"] {
    margin-left: 130px;
  }
}

#messageForm .field {
  .ui.selection.dropdown:not(.multiple)>input[type="text"] {
    padding-left: 0;
  }
}

#messageForm {
  .show-more-suggestions {
    .menu.transition.visible {
      max-height: 62vh;
    }
  }
}

.reply_time {
  color: #5CB459;
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i {
    display: flex;
    align-items: center;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.data-grid-container .data-grid .cell>input {
  border: none !important;
}

.data-grid-container .data-grid .cell.read-only {
  background: inherit !important;
}

.data-grid-container .data-grid .dropdown.fluid.ui .menu.transition.visible {
  width: 100%;
}

.ui.image.label div {
  vertical-align: top;
  height: 2.1666em;
  margin: -0.5833em 0.5em -0.5833em -0.5em;
}

.ui.image.label svg.clsp-svg {
  border-radius: 0.28571429rem 0 0 0.28571429rem;
}

.clsp-orgItem div.ui.avatar.image {
  padding: 0 !important;
}

.clsp-userNot div.ui.avatar.image {
  padding: 0 !important;
  background-color: transparent !important;
}

.ui.secondary.pointing.menu {
  border-bottom: none !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #1c7ed6 !important;
}

.data-editor {
  height: 22px !important;
  text-align: left !important;
}

.value-viewer {
  height: 22px !important;
}

/**
 * Improvement to Table (Fixing Spacing) - Product Team solicitation
 */
.ui.table:not(.calendar-table) tr td:first-child,
.ui.table:not(.calendar-table) tr th:first-child {
  padding-left: 1.57142858em;
}

.ui.table:not(.calendar-table) tr td:last-child,
.ui.table:not(.calendar-table) tr th:last-child {
  padding-right: 1.57142858em;
}

.radio-button-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
}

.radio-title {
  font-size: 16px;
  font-weight: bold;
}

/*Disable text style*/
.disable-text {
  opacity: 0.24;
}

.ui.table.calendar-table tr th {
  padding-left: 0px;
  padding-right: 0px;

  &.header {
    padding-left: 1.57142858em;
    padding-right: 1.57142858em;

    .arrow:hover {
      background-color: rgb(248, 248, 248);
    }
  }
}

/***
 * Styling normal Checkbox to looks like ColoredCheckbox
 */

//the box element
.ui.checkbox:not(.radio):not(.toggle) .box:before,
.ui.checkbox:not(.radio):not(.toggle) label:before {
  border: 0;
  font-family: Icons;
  font-size: 1.5rem;
  font-weight: normal;
  content: "\f0c8";
  color: #cacdcf !important;
}

//the check element
.ui.checkbox:not(.radio):not(.toggle) input:checked~.box:after,
.ui.checkbox:not(.radio):not(.toggle) input:checked~label:after {
  font-family: Icons;
  left: 0.075rem;
  font-size: 0.95rem;
  font-weight: bold;
  content: "\f00c";
  color: #ffffff !important;
}

.ui.checkbox:not(.radio):not(.toggle) input:checked~.box:before,
.ui.checkbox:not(.radio):not(.toggle) input:checked~label:before {
  font-family: Icons;
  font-size: 1.5rem;
  font-weight: bolder;
  color: #0080ff !important;
}

//

.ui.checkbox:not(.radio):not(.toggle) input:indeterminate~.box:after,
.ui.checkbox:not(.radio):not(.toggle) input:indeterminate~label:after {
  font-family: Icons;
  font-size: 1.5rem;
  font-weight: normal;
  content: "\f146";
  color: #0080ff !important;
}

/***
 * Toggle Checkbox
 */

//background
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  width: 2.2rem;
  height: 1.4rem;
  border-radius: 0.7rem;
  // box-shadow: 0.02em 0.02em 0.1em rgba(0, 0, 0, 0.3) inset;
  // box-shadow: 0 0 0 1px rgba(34,36,38,.15) inset,  0.02em 0.02em 0.1em rgba(0, 0, 0, 0.3) inset;
  background-color: #cacdcf !important;
}

//slider
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  margin-top: 0.2rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: #fff;
}

//unchecked slider
.ui.toggle.checkbox input~.box:after,
.ui.toggle.checkbox input~label:after {
  left: 0.2rem;
  // box-shadow: 0.02em 0.02em 0.1em rgba(0, 0, 0, 0.3), 0 0 0 1px #afafb0 inset;
}

//checked slider
.ui.toggle.checkbox input:checked~.box:after,
.ui.toggle.checkbox input:checked~label:after {
  left: 1rem;
  // box-shadow: 0.02em 0.02em 0.1em rgba(0, 0, 0, 0.5), 0 0 0 1px #85afda inset;
}

//Label Spacing
.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
  padding-left: 3rem;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #0078f0 !important;
}

.ui.toggle.checkbox input:focus~.box:before,
.ui.toggle.checkbox input:focus~label:before {
  background-color: #afb3b6 !important;
}

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
  background-color: #afb3b6 !important;
}

.ui.checkbox:not(.radio) .box:before,
.ui.checkbox:not(.radio) label:before {
  top: 0.01rem !important;
}

/***
 * Top Bar Styles
 */

.menuBarParent {
  padding: 16px;
  height: 64px !important;

  &>.menuBarContainer {
    width: 100% !important;

    &>.menu {

      &>.item {
        height: 32px;
        padding: 0;
        margin: 0;
        border-radius: 32px;

        &>.icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;
          font-size: 1.43rem;
          font-weight: 350;
          min-width: 1.5rem;
          width: 32px !important;
          height: 32px !important;

          &.angle {
            font-weight: 1rem;
          }
        }

        &>.active {
          position: absolute;
          top: -4px;
          left: 16px;
          background-color: #BF2600;
          width: 20px;
          height: 20px;
          border-radius: 20px;

          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 100%;

          text-align: center;

          color: #FFFFFF;

          padding: 3px 0;
        }

        &>.avatar {
          height: 32px;
          width: 32px;
          margin: 0;
        }

        &>span {
          padding: 0 12px 0 12px;
        }
      }

      &>.item:last-child,
      .item:first-child {
        border-radius: 32px !important;
      }
    }

    &>.menu.left>.item {
      margin-right: 12px;

      &>.avatar>svg,
      img {
        border-radius: 7px !important;
      }
    }

    &>.menu.right>.item {
      margin-left: 12px;
    }

    &>.menu.right>.item:last-child {
      margin-left: 6px;

      &>span {
        padding: 0 12px 0 8px;
      }
    }
  }
}

.trialButton {
  color: red !important;
}

// Workaround for video menu position in chrome
* {
  will-change: unset !important;
}

.ui.page.dimmer {
  perspective: unset !important;
  -webkit-perspective: unset !important;
}

//
// CSS for mobile
//
@media only screen and (max-width: 992px) {

  .ui.form .field.error .input,
  .ui.form .field.error label,
  .ui.form .fields.error .field .input,
  .ui.form .fields.error .field label {
    color: #000;
  }

  .clsp-errorText {
    color: #c85000;
  }
}

//
// CSS for mobile
//

@media only screen and (max-width: 992px) {

  .ui.form .field.error .input,
  .ui.form .field.error label,
  .ui.form .fields.error .field .input,
  .ui.form .fields.error .field label {
    color: #000;
  }

  .clsp-errorText {
    color: #9f3a38;
  }
}

// Workaround for input focus bug
* {
  will-change: unset !important;
  perspective: unset !important;
}

.step-modal:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0em;
  width: 50%;
  height: 0.0625em;
  background: #E8E8E8;
  z-index: 1;
}

.step-modal:after {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  width: 50%;
  height: 0.0625em;
  background: #E8E8E8;
  z-index: 1;
}

.step-modal {
  position: relative;
}

.step-modal-active {
  position: relative;
}

.step-modal-active:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0em;
  width: 50%;
  height: 0.1875em;
  background: #0080ff;
  z-index: 1;
}

.step-modal-active:after {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  width: 50%;
  height: 0.1875em;
  background: #0080ff;
  z-index: 1;
}

.step-modal-last-active {
  position: relative;
}

.step-modal-last-active:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0em;
  width: 50%;
  height: 0.1875em;
  background: #0080ff;
  z-index: 1;
}

.step-modal-last-active:after {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  width: 50%;
  height: 0.0625em;
  background: #E8E8E8;
  z-index: 1;
}

.ui.list>.item.addressItem {
  display: grid;
  grid-template-columns: 36px auto;
  grid-template-rows: 36px auto;
  grid-template-areas:
    "icon email"
    ". tags"
    ". name"
    ". buttons";
  align-items: center;
  width: 100%;
  padding: 10px 7px !important;
  cursor: default;

  &>i {
    grid-area: icon;
    justify-self: center;
  }

  &>.user-email {
    grid-area: email;
    margin-right: .5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &>.tags {
    grid-area: tags;
    display: flex;
    flex-wrap: wrap;

    &>.ui.label {
      margin: 6px 0 0;

      &:not(:last-of-type) {
        margin-right: .5rem;
      }
    }
  }

  &>.user-name {
    grid-area: name;
    margin: 6px auto 0 0;
  }

  &>.ui.buttons {
    grid-area: buttons;
    margin-top: 10px;
    margin-right: auto;
    white-space: nowrap;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-areas:
      "icon email buttons"
      ".    tags  tags"
      ".    name  name";

    &>.ui.buttons {
      margin: 0 0 0 auto;
    }
  }
}

.terms,
.privacy {
  max-width: 800px;
  padding-bottom: 4.375rem;
  margin: 0 auto;
  margin-right: auto;
  font-size: 1rem;


  ol,
  ul {
    position: relative;
    margin: 0;
    counter-reset: item;
    padding-inline-start: 2rem;

    p {
      margin-top: 1em;
    }
  }

  p,
  li {
    text-align: justify;
  }

  h2 {
    margin-top: 1em;
  }

  ul {
    list-style-type: disc;
    font-size: 0.875rem;
  }

  li {
    margin-top: 1em;
  }

  ul:first-of-type {
    li {
      margin-top: 0.2em;
    }
  }

  ol[type="1"] {
    padding-top: 0.5rem;
    list-style-type: none;
    counter-reset: section;

    >li:before {
      position: absolute;
      left: 0px;
      display: flex;
      justify-content: flex-end;
      width: 1.875rem;
      content: counters(section, ".") ". ";
      counter-increment: section
    }
  }

  ul>li:before {
    content: none;
  }

  &>p:last-of-type {
    margin-top: 2em;
  }

  div>ol {
    p {
      margin-top: 0;
      text-align: center;
      width: 100%;
    }

    sup,
    sub {
      font-size: 0.9rem;
    }

    li:first-of-type {
      margin-top: 0;
    }

    >div {
      display: flex;
      padding: 0;
      border-bottom: 1px solid black;

      >div {
        padding: 1rem 1rem 1rem 3.9rem;

        @media only screen and (max-width: 481px) {
          & {
            padding: 0.7rem 0.7rem 0.7rem 3.1rem;
          }
        }

        >div {
          border-bottom: 1px solid black;
          padding: 1rem;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }

      >div:first-of-type {
        border-right: 1px solid black;
        padding: 0;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    >div:first-of-type {
      >div {
        width: 50%;
        padding: 1rem;
      }
    }
  }

  .simple-table {
    margin: 1rem 0 2rem -3rem;
    padding: 0;
    border: 1px solid black;

    p {
      margin-top: 0;
    }

    >div {
      display: flex;
      padding: 0;
      border-bottom: 1px solid black;

      div {
        width: 50%;
        padding: 1rem;
        text-align: center;

        p {
          height: 100%;
        }

        &:first-of-type {
          border-right: 1px solid black;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  @media only screen and (max-width: 767px) {

    // all levels
    & {
      padding-left: 2.1875rem; //35px
      font-size: 0.9375rem; // 15px

      li:before {
        width: 1.625rem;
      }
    }
  }

  @media only screen and (max-width: 481px) {

    // all levels
    & {
      padding-left: 0.9375rem; // 25px

      li:before {
        width: 1.25rem;
      }
    }
  }
}

.avoid-header-overlapping::before {
  display: block;
  content: " ";
  margin-top: -2rem;
  height: 2rem;
  visibility: hidden;
  pointer-events: none;
}

.free-profile-button:hover {
  background-color: #F2F7FF;
  border-color: #0069FF !important;
}

.step-onboarding-steps:before,
.step-onboarding-steps-active:before {
  top: 0.83em !important;
  height: 0.3em !important;
}

.step-signup-steps:before,
.step-signup-steps-active:before {
  top: 1.125rem !important;
  height: 0.143rem !important;
}

#OrganizationMoments,
#EntityMoments {
  max-width: 616px;
  margin: auto;

  // workaround to make loader visible in modal (https://github.com/Semantic-Org/Semantic-UI-React/issues/3133)
  .ui.dimmer .ui.workaround.loader:before {
    border-color: rgba(0, 0, 0, .1);
  }

  .ui.dimmer .ui.workaround.loader:after {
    border-color: #767676 transparent transparent;
  }
}

#UserPayments,
#UserNotificationsEdit,
#UserPasswordForm,
#UserAccountForm {
  max-width: 900px;
  margin: 0 auto;
}

#AdminOrganizations,
#AdminTrial,
#AdminUsers,
#AdminSettings {
  max-width: 70%;
  min-width: 900px;
  margin: 0 auto;
}

/*Loader*/

.loading-ui {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 4rem;

  &>.loader-ui {
    border: 3px solid #d6d4d4;
    /* Light grey */
    border-top: 3px solid #a5a5a5;
    /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.entity-label {
  display: flex;
  align-items: center;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  padding: 7px;
  border-radius: 200px;
}

.message-tags {

  &.mobile {
    overflow-x: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .message-tag {
    font-weight: 300;
    padding: 12px;
    display: inline-flex;
    height: 48px;
    font-size: 1.143rem;
    align-items: center;
    margin: 0 8px 8px 0;
    border-radius: 200px;

    &.mobile {
      height: 40px;
      font-size: 0.875rem;
      display: flex;
    }
  }
}

.field.noMargin>.ui.input {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
}

.field.noMargin.subject>.ui.input>input {
  font-size: 1.714rem !important;
  font-weight: 700;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}

.field.noMargin.subject.mobile>.ui.input>input {
  font-size: 1.143rem !important;
}

.ql-container>.ql-editor {
  font-size: 1.143rem;
  font-weight: 400;
}

.portal-div {
  width: 100%;
}

@import "./variables.scss";

.error-warning-red {
  margin-top: 6px;
  margin-bottom: 12px;
  color: $main-red;

  &>.icon {
    margin-right: 0.5rem;
    font-weight: 900;
  }
}

.messageItem .sub-header {
  &.nowrap {
    flex-wrap: nowrap;
  }

  .message-status-icons-list {
    display: flex;

    &>* {
      margin: 8px 6px auto 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.messageItem .sub-header {
  &.nowrap {
    flex-wrap: nowrap;
  }

  .message-status-icons-list {
    display: flex;

    &>* {
      margin: 8px 6px auto 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media print {
  .ui.page.modals {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 !important;
    width: 100%;
    overflow: visible !important;

    .modal {
      position: static !important;
      width: 90% !important;
      height: auto !important;
      margin: auto !important;
      box-shadow: unset !important;

      .close {
        display: none;
      }

      .content {
        max-height: unset !important;
      }
    }

    #MessageNode>.header {
      display: none;
    }
  }

  body.dimmed>#root {
    display: none;
  }

  tr,
  .replyItem,
  .card {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  td,
  th {
    word-break: break-word;
    min-width: 5rem;
    page-break-inside: avoid;
    page-break-after: auto;
  }
}

#SurveyResults {
  @media only screen and (max-width: 767px) {
    .content {
      table {
        thead {
          tr {
            background: #f9fafb !important;
          }
        }
      }
    }

    .ui.table:not(.unstackable) thead {
      display: revert;
    }

    .ui.table:not(.unstackable) tr>td,
    .ui.table:not(.unstackable) tr>th {
      border-left: 1px solid rgba(34, 36, 38, .1) !important;
      padding: .92857143em .78571429em !important;
      -webkit-box-shadow: revert;
      box-shadow: revert !important;
    }

    .ui.table:not(.unstackable) tbody,
    .ui.table:not(.unstackable) tr,
    .ui.table:not(.unstackable) tr>td,
    .ui.table:not(.unstackable) tr>th {
      display: revert !important;
    }
  }
}

#MessageNode {
  #MessageMedias {
    .container {
      margin: 0px !important;
    }
  }
}

.ui.top.conversation-news-top-banner {
  background-position: 0px 82%, 1000%;
  font-size: 15px;

  .ui.container {
    padding: 0 46px 0 240px;
  }

  @media only screen and (min-width: 1023px) {
    font-size: 16px;
    background-position: 10px 82%, calc(100vw - 280px) 82%;


    .ui.container {
      padding: 0 310px;
    }
  }

  &.compact {
    .ui.container {
      padding: 0 50px;
    }
  }

  // hide banner in mobile screens
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.ui.container.conversation-news-top-banner-content {
  width: 100%;

  @media only screen and (max-width: 1170px) {
    width: 60%;
  }

  @media only screen and (max-width: 900px) {
    width: 40%;
  }
}

/* Toggle Switch */
.toggle-switch {
  &>input {
    display: none;
  }

  &>.switch {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    padding: .5rem 1rem;
    background-color: #FFFFFF;
    border-radius: 100px;
    border: 1px solid $border-gray;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &>span {
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
      z-index: 9;
      transition: color .2s ease;

      &.focused {
        color: #fff;
        text-shadow: 0px 0px .5px #fff;
        transition: all .3s ease;
      }
    }

    &:before {
      position: absolute;
      content: "";
      left: -1px;
      top: -1px;
      width: calc(50% + .5rem + 2px);
      height: calc(100% + 2px);
      background-color: $main-blue;
      border-radius: 100px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s ease;
    }

    &.toggled:before {
      transform: translateX(calc(100% - 1rem));
    }
  }

  &.compact {
    .switch {
      padding: .325rem 1rem;
      column-gap: 1.5rem;

      &:before {
        width: calc(50% + .25rem + 2px);
        box-shadow: none;
      }

      &.toggled:before {
        transform: translateX(calc(100% - .75rem + 1px));
      }

      span.focused {
        font-weight: 500;
        text-shadow: none;
      }
    }
  }

  &.disabled {
    &>.switch {
      cursor: default;

      &:before {
        background: $disabled-blue;
        box-shadow: none;
      }
    }
  }
}


#SurveyResults {
  @media only screen and (max-width: 767px) {
    .content {
      table {
        thead {
          tr {
            background: #f9fafb !important;
          }
        }
      }
    }

    .ui.table:not(.unstackable) thead {
      display: revert;
    }

    .ui.table:not(.unstackable) tr>td,
    .ui.table:not(.unstackable) tr>th {
      border-left: 1px solid rgba(34, 36, 38, .1) !important;
      padding: .92857143em .78571429em !important;
      -webkit-box-shadow: revert;
      box-shadow: revert !important;
    }

    .ui.table:not(.unstackable) tbody,
    .ui.table:not(.unstackable) tr,
    .ui.table:not(.unstackable) tr>td,
    .ui.table:not(.unstackable) tr>th {
      display: revert !important;
    }
  }
}

.deletedForUserList {
  .userListHeader {
    display: flex;
    align-items: center;

    font-weight: 700;
    line-height: 18px;
  }

  .userList {
    margin-top: 1.5rem; // 24px

    li {
      display: flex;
      justify-content: center;
      align-items: center;

      list-style: none;
      font-size: 0.875rem; // 14px

      >span {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 1.3125rem; // 21px
        min-width: fit-content;
        max-width: 6.75rem; // 108px
        border-radius: 1rem; // 16px
        border: 1px solid #00000029;

        padding: 0 0.625rem 0 0.625rem; // 4px 10px 4px 10px

        text-overflow: ellipsis;
      }

      >time {
        margin-left: 0.9375rem; // 15px

        min-width: fit-content;

        color: #00000099;
      }

      &+li {
        margin-top: 0.875rem; // 14px
      }
    }
  }
}

#MessageNode {
  #MessageMedias {
    .container {
      margin: 0px !important;
    }
  }
}

.segment-hover-button:hover {
  background-color: #CACBCD;
}

#UserNotificationsEdit {
  &.mobile {

    .ui.column.grid>[class*="two wide"].column,
    .ui.grid>.column.row>[class*="two wide"].column,
    .ui.grid>.row>[class*="two wide"].column,
    .ui.grid>[class*="two wide"].column {
      width: 20.5% !important;
      padding-right: 0px;
      display: flex;
      align-items: center;
    }

    h3 {
      font-size: 16px;
    }
  }
}

#inputBox {
  padding-left: 1em;
  border-radius: 1.5rem;
  text-align: center;

  & .menu.transition.visible {
    max-height: 20rem;
  }
}

#ArrivedForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .header-container {
    width: 100%;
    display: flex;
    align-items: center;

    h1 {
      padding-left: 8px;
      line-height: 38px;
      font-size: 2.29rem;
      margin: 0 0.25rem 0 0;
    }

    i {
      margin-top: 0.5rem;
      font-size: 22px;
      font-weight: 300;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.24);

      transition: color 0.2s;

      &:hover {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .content {
    margin: 16px auto 36px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .subheader-container {
      margin-bottom: 28px;

      width: 100%;
      display: flex;
      align-items: center;

      h3 {
        font-size: 16px;
        margin: 0 0.25rem 0 0;
      }

      i {
        font-size: 16px;
        font-weight: 300;
        line-height: 100%;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.24);

        transition: color 0.2s;

        &:hover {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    >.field {
      display: flex;
      width: 100%;


      >.checkbox.pointer {
        margin-right: 12px;
        padding: 14px 16px;
        border-radius: 200px;
        height: fit-content;
        width: fit-content;

        &.checked {
          background: #F2F7FF;
        }
      }

      &.enabled {
        label {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          max-width: 102px;
          font-size: 0.875rem;

          padding-left: 112px;
          color: #999999 !important;

          &::before {
            width: 3.664rem; // 67px
            height: 1.969rem; // 36px
            border-radius: calc(1.969rem / 2);
            top: unset !important;
          }

          &::after {
            left: 0.25rem; // 4px

            width: 1.586rem; // 29px
            height: 1.586rem; // 29px
            border-radius: calc(1.586rem / 2);
            top: unset !important;
            margin-top: 0;
          }
        }
      }

      .field {
        width: 100%;

        >textarea {
          min-height: 120px;
        }
      }

      &+.field {
        margin-top: 28px;
      }
    }

    .checkbox.checked {
      &.toggle label {
        &::before {
          background-color: $main-blue !important;
        }

        &::after {
          left: 1.881rem; // 34.4px
        }
      }

      .check {
        color: $main-blue !important;
      }
    }

    .checkbox.aligned {
      display: flex;
      align-items: center;
    }

    .checkbox.error {
      color: #BF2600;

      i {
        color: #BF2600 !important;
      }
    }
  }

  &>div:last-of-type {
    margin: auto;

    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  &.translucent {
    .content {
      >.field {
        opacity: 0.4;

        .checkbox {
          opacity: 1 !important;

          &:not(.checked) i {
            color: rgba(0, 0, 0, 0.16) !important;
          }
        }
      }

      .info-container>i {
        opacity: 0.4;
      }

      & .transparent {
        color: #000 !important;
        border-color: rgba(0, 0, 0, 0.16);
        opacity: 0.4;
      }
    }

    button[data-action="submit"] {
      opacity: 1 !important;
    }
  }
}

.concierge-item {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 24px;
  margin-top: 1rem;

  .info-container {
    display: flex;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 260px;

      .concierge-name {
        font-size: 18px;
        line-height: 23px;
      }

      .concierge-address {
        font-size: 13px;
        line-height: 17px;
        color: #999999;
      }
    }
  }

  .groups-container {
    .global-tag {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 16px;
    }
  }

  .pen {
    margin-left: 102px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    aspect-ratio: 1/1;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 200px;

    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, .60);

    cursor: pointer;
  }

  .trash {
    margin-left: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    aspect-ratio: 1/1;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 200px;

    font-weight: 300;
    font-size: 16px;
    color: rgba(0, 0, 0, .60);

    cursor: pointer;
  }
}

.arrived-paywall-image {
  margin: 0
}

.arrived-paywall-content {
  margin-top: 16px;
  text-align: left;

  p,
  span {
    text-align: left;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    list-style: none;

    li:before {
      content: '✓';
    }
  }
}

#ArrivedIntro {
  max-width: 75rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  >div {
    margin-top: 24px;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 22px;
    }

    span {
      text-align: left;
      margin-top: 25px;
    }

    ul {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      list-style: none;

      li+li {
        margin-top: 25px;
      }

      li:before {
        content: '✓';
        color: #084FFF;
      }
    }

    button {
      margin-top: 25px;
      align-self: flex-start;
    }
  }
}

@media (max-width: 1200px) {
  #ArrivedIntro {
    flex-direction: column;

    >div {
      margin-right: unset;
    }

    img {
      transform: scale(0.8);
    }
  }
}

.warning-box {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  border: 1px solid #BF2600;
  border-radius: 8px;

  color: #BF2600;
  font-size: 16px;

  >div {
    >i {
      font-weight: 600;
      margin-right: 16px;
    }
  }

  >button {
    border: none;
    background: none;
    color: #0069FF;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.popupTag {
  i {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  +.popupTag {
    margin-top: 8px;
  }
}

.scroll-popup {
  max-height: 200px;
  max-width: 280px;
  overflow: auto;
}

#OrganizationHistory.scrollable-table {
  .global-table {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table-header,
  .table-row,
  .actions {
    min-width: 80rem;
  }
}

#EntityDashboard .recharts-surface {
  overflow: visible;
}

.ui.active.visible.fluid.multiple.search.selection.scrolling.dropdown.icon-left.custom-label.round-corners.small-suggestion-avatars>input {
  display: flex;
  flex: 1;
}

.multiselect {
  position: relative;

  &>button {
    padding: 1rem;

    &>div {
      display: flex;
      align-items: center;
      font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

      &>.filters-number {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1.125rem;
        height: 1.125rem;
        margin: 0 0 0 .4rem;
        color: #fff;
        font-size: .75rem;
        border-radius: 1rem;
        background: $main-blue;

        &>* {
          line-height: 100%;
        }
      }
    }
  }

  &>.options-menu {
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    padding-top: .75rem;
    background: #fff;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: 12px;
    z-index: 10;

    &>.menu-item-container {
      overflow-y: auto;

      &>.input-component {
        margin: 0 .5rem;

        &>label {
          width: 100%;
          max-width: 100%;
          padding: .5rem .75rem .5rem 2.75rem;

          &:before {
            border-radius: .375rem;
          }

          &>i.icon {
            left: .75rem;
          }
        }

        &.search input,
        &.search input[type=text]:hover {
          box-shadow: none;
          color: rgba(0, 0, 0, 1);
        }

        &.search ::-webkit-input-placeholder {
          //for chrome
          box-shadow: none;
          color: rgba(0, 0, 0, 0.4);
          text-decoration: underline;
        }

        &.search ::-moz-placeholder {
          //for fiferox and others
          box-shadow: none;
          color: rgba(0, 0, 0, 0.4);
          text-decoration: underline;
        }


        &.select-all {
          color: $main-blue;

          &>label:before {
            background: none;
          }
        }

        &.no-box {
          label {
            padding: .5rem 1rem;

            &>i.icon {
              display: none;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 2px;
        }
      }

      &>.selected {
        display: flex;
        flex-wrap: wrap;
        padding-right: .5rem;
        margin-bottom: 1rem;

        &>button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: .5rem 0 0 .5rem;
          padding: .5rem;

          &>i.icon.times {
            margin: 0 .5rem;
          }
        }
      }

      &>.limited-warning {
        color: $main-gray;
        font-size: .875rem;
        margin: 1rem;
      }
    }

    &>.bottom-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: .75rem .5rem;
      background: #fff;

      &>button {
        display: flex;
        align-items: center;
        min-height: 2.5rem;
        padding: .5rem 1rem;

        &.clear-all {
          text-decoration: underline;
          border: none;
        }
      }
    }

    &>.search-header {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px;


      &>.icon.icon-before {
        position: absolute;
        font-size: 0.875rem;
        margin: 0 0 5px 10px;
      }

      &>.search-input {
        height: 38px;
        width: 100%;
        padding-left: 32px;
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  #ConciergeAdd.fix-modal-position {
    margin-left: -47.5% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #ConciergeAdd.fix-modal-position {
    width: 540px !important;
  }
}

#OrganizationHistory .custom-label-color-size {
  font-size: 19px !important;
}

#OrganizationArrived {
  .ArrivedHeader {

    h1,
    i {
      font-size: 15px !important;
    }
  }

  .ArrivedTab a {
    font-size: 11px;
  }

  #ArrivedDashboard {
    >span {
      margin: 0 auto 18px auto;
      display: flex;
      max-width: 975px;
      opacity: 0.4;
      font-size: 13px;
    }

    #DashboardAdhesion {
      margin-top: -1px;
      padding: 12px 16px 11px 16px !important;

      .loading-container {
        height: 100%;
      }

      >div:first-of-type {
        align-items: center;
        padding: 0 !important;

        .dashboard-title-container {
          display: flex;
          justify-content: center;
          align-items: center;

          .dashboard-title {
            font-size: 9px !important;
          }

          .dashboard-info {
            display: flex;
            align-items: center;
            font-size: 9px !important;
          }
        }

        .ui.inline.dropdown {
          padding: 9px 10px !important;
          font-size: 10px;
          line-height: 13px;

          .text {
            font-weight: normal;
          }

          div[role="option"] {
            font-size: 13px;
            padding: 8px 13px !important;
          }
        }

        .ui.button {
          padding: 9px 10px !important;
          font-size: 10px;
          font-weight: normal;
          line-height: 13px;
          margin-left: 8px !important;
        }
      }

      #ArrivedDashboardAdhesion {
        margin-top: 17px;

        .ui.progress {
          margin: 0 0 10px;

          .bar {
            height: 6px;
          }
        }

        .text-content-container {
          margin-top: 18px;
          ;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: unset;

          .legend-container {
            margin-left: -5px;
            width: 77px;
            height: 43px;
            padding: 3px 7px 4px 5px;

            >span {
              font-size: 15px;
              font-weight: bold;
              line-height: 20px;
            }

            >div {
              margin-top: 2px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              >i {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 3px;

                &.red {
                  background-color: #E75353;
                }

                &.yellow {
                  background-color: #F2C617;
                }

                &.green {
                  background-color: #74B816;
                }
              }

              >span {
                opacity: .6;
                text-transform: uppercase;
                color: #6C6C6C;
                font-size: 9px;
                line-height: 12px;
              }
            }
          }

          .adhesion-container {
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 43px;

            .percentage {
              color: #1D1D1D;
              font-size: 25px;
              line-height: 14px;
              font-weight: bold;
            }

            .total-count {
              color: #999999;
              font-size: 9px;
              line-height: 9px;
              margin-top: 9px;
            }
          }

          .pending-container {
            margin-right: -7px;
            width: 67px; // (57 / 134 * 157) -> 67
            height: 43px;
            padding: 3px 7px 4px 5px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .data-button-container {
              display: flex;
              justify-content: flex-end;

              >span {
                color: #1D1D1D;
                font-size: 15px;
                font-weight: bold;
                line-height: 21px;
              }

              >i {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                margin: 6px 0px 6px 3px;
                border: 1px solid #00000029;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1px 0 0 1px;
                font-size: 4px;
              }
            }

            .pending-legend-container {
              margin-top: 2px;
              min-width: fit-content;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              >i {
                background-color: #E8E8E8;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 4px;
              }

              >span {
                opacity: .6;
                text-transform: uppercase;
                color: #6C6C6C;
                font-size: 9px;
                line-height: 12px;
              }
            }

            &.hoverable:hover {
              background-color: #F2F7FF;

              .data-button-container>i {
                color: #FFFFFF;
                background-color: #0069FF;
                border: none;
              }
            }
          }
        }

      }
    }
  }

  @media (min-width: 991px) {
    .ArrivedHeader {

      h1 {
        font-size: 28px !important;
      }

      i {
        font-size: 20px !important;
      }
    }

    .ArrivedTab a {
      font-size: 14px;
    }

    #ArrivedDashboard {
      >span {
        margin: 18px auto 0 auto;
        font-size: 16px;
      }

      #DashboardAdhesion {
        margin: 18px auto 0 auto;
        max-width: 975px;
        height: 300px;
        padding: 30px 45px 30px 40px !important;

        >div:first-of-type {
          .dashboard-title-container {
            .dashboard-title {
              font-size: 18px !important;
            }

            .dashboard-info {
              font-size: 18px !important;
            }
          }

          .ui.inline.dropdown {
            padding: 14px 16px !important;
            font-size: 16px;

            div[role="option"] {
              font-size: 16px;
              padding: 11px 16px !important;
            }
          }

          .ui.button {
            padding: 14px 27px !important;
            font-size: 16px;
            line-height: 16px;
            margin-left: 20px !important;
          }
        }

        #ArrivedDashboardAdhesion {
          margin-top: 42px;

          .ui.progress {
            margin: 0 0 18px;

            .bar {
              height: 13px;
            }
          }

          .text-content-container {
            margin-top: 30px;

            .legend-container {
              margin-left: -9px;
              width: 165px;
              height: 74px;
              padding: 6px 12px 12px 9px;

              >span {
                font-size: 24px;
                line-height: 33px;
              }

              >div {
                margin-top: 0;

                >i {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin-right: 10px;
                }

                >span {
                  font-size: 13px;
                  line-height: 25px;
                }
              }
            }

            .adhesion-container {
              height: 102px;
              margin-top: 6px;

              .percentage {
                font-size: 30px;
                line-height: 33px;
              }

              .total-count {
                font-size: 13px;
                line-height: 13px;
                margin-top: 6px;
              }
            }

            .pending-container {
              margin-right: -12px;
              width: 114px;
              height: 74px;
              padding: 6px 12px 12px 9px;
              border-radius: 8px;

              .data-button-container {
                >span {
                  font-size: 24px;
                  line-height: 33px;
                }

                >i {
                  width: 18px;
                  height: 18px;
                  margin: 8px 0px 7px 5px;
                  border: 1px solid #00000029;
                  padding: 1px 0 0 2px;
                  font-size: 8px;
                }
              }

              .pending-legend-container {
                margin-top: 0;

                >i {
                  width: 10px;
                  height: 10px;
                  margin-right: 8px;
                }

                >span {
                  font-size: 13px;
                  line-height: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ui.tiny .value.force-30px.bold {
  font-size: 30px !important;
}

.ui.top.item.right.force-margin-0 {
  margin: 0 !important;
}

.translate-child {
  margin-left: 15px;
  margin-bottom: 20px;

  &:hover {
    a.ui.card {
      transform: translateY(-3px)
    }
  }
}

.single-accordion {
  font-size: 16px;

  .single-accordion-title {
    font-weight: bold;

    &>* {
      cursor: pointer;
    }
  }
}

#ChargeForm {

  .ui.form label,
  .charge-form-label,
  .input-component label {
    margin-bottom: 12px !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .bank-dropdown.mobile>.form-dropdown {
    margin-bottom: 0px;
  }

  .single-accordion {
    margin: 26px 0 40px;

    .single-accordion-content {
      margin-top: 28px;
    }
  }

  .advanced-settings.columns-view {
    display: flex;
    flex-wrap: nowrap;

    &>* {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      opacity: 1;

      &> :first-child {
        margin-bottom: 12px;
        opacity: 1;
      }

      &> :not(:first-child) {
        display: flex;
        height: 50px;
        align-items: center;
      }
    }

    .toggle-switch {
      width: fit-content;
      margin-bottom: 0;
      font-size: 16px;
    }
  }
}

.charge-form:not(.mobile)>*:not(:last-child) {
  margin-bottom: 16px;
}

.charge-form.mobile>*:not(:last-child),
.charge-form.mobile .single-accordion-content>*:not(:last-child) {
  margin-bottom: 28px;
}

#BankConfirmationModal {
  & p {
    font-size: 16px;

    &>span {
      font-weight: bold;
    }
  }
}

.signature-cpf {
  display: flex;
  width: 100%;
  min-height: 3rem;
  align-items: center;
  justify-content: center;
  margin: 1.25rem 0;
  border: 1px solid #DEE2E6;
  border-radius: 200px;

  &.disabled {
    opacity: 0.4;
  }

  &:not(.disabled) {
    cursor: text;
  }

  &.focus {
    border-color: #0069FF;
  }

  & *:not(i) {
    font-size: 16px !important; // FID-361 -> caused by conflict with Semantic
    font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important; // FID-361 -> caused by conflict with Semantic
  }

  &>i {
    font-size: 16px;
    line-height: 1.2;
  }

  // "disabled" class style should be set before "valid" class
  &.disabled {
    color: rgba(153, 153, 153, 1);
    border-color: rgba(153, 153, 153, 1);
  }

  &.valid {
    color: #21BA45;
    border-color: #21BA45;

    & .signature-input:disabled {
      -webkit-text-fill-color: #21BA45; // fix safari color bug on disabled inputs (issue FID-317)
    }
  }

  &.invalid {
    color: #BF2600;
    border-color: #BF2600;
  }

  & .unchecked {
    color: #6C6C6C;
  }

  & .hidden {
    visibility: hidden;
    display: none;
  }
}

.signature-input {
  border: none !important;
  width: 9rem !important;
  margin-left: 1rem !important;
  color: inherit !important; // FID-361 -> caused by conflict with Semantic
  outline: none;
  caret-color: #0069FF;

  &::placeholder {
    color: #6C6C6C !important; // FID-361 -> caused by conflict with Semantic
    opacity: 1; // fix placeholder opacity on firefox (gecko)
  }

  &:focus::placeholder {
    color: transparent;
  }
}

.signature-name {
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #DEE2E6;
  border-radius: 200px;

  & p {
    font-family: Allison;
    font-size: 36px;
    color: #0080FF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    height: 3rem;
    line-height: 3rem;
  }
}

.signature-status {
  margin-top: 1.25rem;
  margin-bottom: 0.2rem;
  text-align: center;

  &.canceled {
    color: #BF2600;
  }

  &.success {
    color: #21BA45;
  }

  &.warning {
    color: #FFB43C;
  }

  &.expired {
    color: #6C6C6C;
  }
}

#BankConfirmationModal {
  & p {
    font-size: 16px;

    &>span {
      font-weight: bold;
    }
  }
}

.new-tag {
  color: #0069FF;
  border: 1px solid #0069FF;
  border-radius: 6px;
  padding: .1rem .4rem;
  margin-left: 8px;
}

.replyItem {
  & .fullname-container {
    margin-left: 10px;
    font-size: 14px;
    color: #666666;
    border-width: 1px;
    border-radius: 6px;
    padding: 1px 3px 1px 3px;
  }
}

.arrived-banner {
  margin-left: 35px;
  margin-top: -20px;

  &>.disclaimer {
    width: 150px;
    color: #A5A5A5;
    font-size: 12px;
  }

  &>.close-banner:hover {
    cursor: pointer;
  }

  &>.banner:hover {
    cursor: pointer;
  }
}

.MessageListContainerDefault {
  max-width: 70rem;
  margin: auto;
}

.MessageListContainerWithBanner {
  width: calc(100% - 200px);
  max-width: 1024px;
}

@media only screen and (max-width: 1250px) {
  .MessageListContainerWithBanner {
    width: 100%;
    max-width: 70rem;
    margin: auto;
  }

  .arrived-banner {
    display: none;
  }
}

.feature-toogle-container {
  width: 100%;
  min-height: 56px;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &>.icon {
    margin: 0 20px 0 20px;
  }

  &>.text {
    margin: 5px 0 5px 0;
    flex: 1;
  }

  &>.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    margin: 0 20px 0 20px;

    * {
      cursor: pointer;
      max-width: 180px;
    }

    :nth-child(1) {
      margin-right: 20px;
    }
  }
}

#entity-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;

  .entity-information-icon {
    margin-right: 10px;
    & > .icon {
      color: #999999;
      font-weight: 300;
    }
  }

  & > .info-text {
    font-size: 16px;
    color: #999999;
    font-weight: 450;
  }
}
.approval-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 825px) {
    flex-direction: column;
    justify-content: space-evenly;
  }

  * {
    border-radius: 100%;
    height: 50px;
    width: 50px;
    cursor: pointer;
    margin: 0 10px 0 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .icon {
      background-color: transparent;
      font-weight: 300;
      font-size: 18px;
    }
  }

  & > .approve-button {
    border: solid #ADE2C9 1px;
    color: #00A656;

    &:hover {
      background-color: #00A656;
      color: white;
      border: none;
    }

    &:active {
      background-color: #008B48;
      color: white;
      border: none;
    }
  }

  & > .reprove-button {
    border: solid #E5A899 1px;
    color: #BF2600;

    &:hover {
      background-color: #BF2600;
      color: white;
      border: none;
    }

    &:active {
      background-color: #A02000;
      color: white;
      border: none;
    }
  }

  & > .edit-button {
    border: solid #CCCCCC 1px;
    color: #000000;

    &:hover {
      background-color: #E5E5E5;
      color: #000000;
      border-color: #999999;
    }

    &:active {
      background-color: #E5E5E5;
      color: #000000;
      border-color: #999999;
    }
  }
}

.alert {
  display: flex;
  padding: 1rem 1.5rem;
  border-radius: .5rem;
  max-width: 100%;
  word-break: break-word;

  & > i.icon {
    font-size: 1.5rem;

    &:not(.times) {
      margin-right: 1rem;
      font-weight: 900;
    }

    &.times {
      width: 1.5rem;
      margin-left: 1rem;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  }

  & > span { align-self: center; }
}

.alert.visible {
  opacity: 1;
  padding: 0;
  transition: all 1s ease;
}

.alert.invisible {
  opacity: 0;
  padding: 0;
  transition: all 1s ease;
}

.background.green {
  background-color: #e3fcef;
}

.background.red {
  background-color: #ffebe6;
}

.background.gray {
  background-color: #f1f3f4;
}

#CardForm .ui.mini.input input {
  padding: 0.574em 0.875em;
  font-size: 0.875em;
}

#OrganizationForm .ui.segment, .ui.segments .segment {
  font-size: 0.875rem;
}

#OrganizationForm .ui.segment {
  padding: 0.875rem 0.875rem;
  margin: 0.875rem 0;
}

#OrganizationForm .ui.search.selection.dropdown>input.search {
  line-height: 0.93rem;
  padding: 0.574rem 1.838rem 0.574rem 0.875rem;
}

#OrganizationForm .ui.labeled.input>.label:not(.corner) {
  padding-top: .7875em;
}

#CommitmentPopup h5.ui.header {
  font-size: 0.875rem !important;
}
