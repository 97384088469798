#EntityAdd,
#EntityEdit,
#ChannelAdd,
#ChannelEdit,
#IntegrationAdd,
#IntegrationAdminForm,
#IntegrationAdminAdd,
#IntegrationAdminEdit,
#ModuleAdd,
#ModuleEdit,
#ModuleForm,
#ContractAdd,
#ContractEdit,
#ContractForm {

  // @media only screen and (max-width: 767px) {
  //   .ui.modal > .actions {
  //       padding: 1rem 1rem !important;
  //   }
  // }

  .content {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .menu {
    border: none;
  }

  .status::before,
  .entity:before,
  .integration:before {
    background: none;
  }


  .ui.two.item.menu {
    justify-content: left;
  }
  
  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 32px;

    &:not(.autowidth) {
      width: 153.33px;
    }

    height: 46px;

    >span {
      font-size: 1.143rem;
      margin: 8px;
    }

    &.small {
      >span {
        font-size: 1rem;
      }
    }
  }

  .entity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 16px;
    margin-left: 8px !important;
    margin-right: 8px !important;

    &:not(.autowidth) {
      width: 153.33px;
    }

    height: 123px;

    &.small {
      height: 110px;
    }

    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 32px;

    >.icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 48px;
      height: 48px;

      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      align-items: center;
    }

    >span {
      font-size: 1.143rem;
      margin: 8px;
    }

    &.small {
      >span {
        font-size: 1rem;
      }
    }

    &.big {
      width: 238px;
      height: 140px;
    }
  }

  .integration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: 8px !important;
    margin-right: 8px !important;

    height: 67px;

    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 32px;

    >span {
      font-size: 1.143rem;
      margin: 8px;
    }

    &.small {
      >span {
        font-size: 1rem;
      }
    }
  }

  .status.item:first-child,
  .entity.item:first-child,
  .integration.item:first-child {
    margin-left: 0 !important;
  }

  .status.item:last-child,
  .entity.item:last-child,
  .integration.item:last-child {
    margin-right: 0 !important;
  }

  .entity:hover,
  .integration:hover {
    border: 1px solid rgba(8, 78, 255, 0.50);
    background-color: #FFFFFF;
    // margin-top: -2px;
    // box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.16);
  }

  .status.active {
    background: rgba(242, 247, 255, 1);
    >span {
      font-size: 1.143rem;
    }
  }

  .entity.active,
  .integration.active {
    border: 4px solid #084eff;
    background-color: #FFFFFF;

    >.icon {
      top: 19px;
    }

    >span {
      top: 64px;
      margin: 8px 8px 5px 8px;
    }
  }

  .entity.active:hover,
  .integration.active:hover {
    background-color: #FFFFFF;
  }
}