#PureSearchDropdown {
    @import "../variables.scss";

    position: relative;
    display: inline-block;
    width: 100%;

    & > .error-text {
        color: rgb(191, 38, 0);
    }

    & > #myInput {
        background-color: #FFF;
        font-size: 16px;
        padding: 14px 40px 12px 40px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        display: flex;
    }

    & > #myInput.error {
        border: 1px solid #bf2600;   
    }

    & > .icon {
        position: absolute; 
        top: 1rem;
        cursor: pointer;
    }

    & > .icon.clearable {
        right: 1rem;
    }

    & > .icon.before {
        left: 1rem;
    }

    & > .icon.loading {
        right: 1rem;
        font-size: 1.3rem;
        display: inline-block;
    }

    & > .dropdown-content {
        display: none;
        width: 100%;
        max-height: 300px;
        position: absolute;
        background-color: #FFFFFF;
        min-width: 230px;
        overflow: auto;
        border: 1px solid #ddd;
        z-index: 100;

        & > .dropdown-item {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        & > .dropdown-item:hover {
            background-color: rgba(0,0,0,.03);
        }
      }
      
      & >  .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }

    & > .show {display: block;}

    
}