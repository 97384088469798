#ConciergeAdd,
#EntityAdd,
#EntityEdit,
#ChannelAdd,
#ChannelEdit,
#IntegrationForm,
#SelectUser,
#SelectGroup,
#RoleForm,
#IntegrationAdminForm,
#IntegrationAdminAdd,
#IntegrationAdminEdit,
#ModuleAdd,
#ModuleEdit,
#ModuleForm,
#ContractAdd,
#ContractEdit,
#ContractForm {
    @import "../variables.scss";

    .global-tag {
        display: flex;
        align-items: center;
        height: 2rem;
        width: fit-content;
        max-width: 100%;
        // padding: 0 0.375rem;
        padding: 14px 16px 14px 16px;
        margin-left: 0.75rem;
        border-radius: 200px;
        background-color: $background-blue;
        color: $main-blue;

        & > span {
            font-size: 1rem;
            font-weight: 400;
            line-height: 19px;
            // font-weight: 700;
            // line-height: 100%;
            //letter-spacing: 0.02em;
            // white-space: nowrap;
        }

        & > .icon {
            margin: 0 0 3px 4px;
            --fa-primary-color: white;
            cursor: pointer;
        }
    }

    .coloured.blue {
        color: $main-blue;
    }

    .coloured.green {
        color: $main-green;
    }

    .coloured.yellow {
        color: $main-yellow;
    }

    .coloured.red {
        color: $main-red;
    }

    .coloured.gray {
        color: $main-gray;
    }

    .coloured.black {
        color: $main-black;
    }

    .background.blue {
        background-color: $background-blue;
    }

    .background.green {
        background-color: $background-green;
    }

    .background.yellow {
        background-color: $background-yellow;
    }

    .background.red {
        background-color: $background-red;
    }

    .background.gray {
        background-color: $background-gray;
        color: $main-black;
    }

    .background.disabled {
        background-color: rgba(0, 0, 0, 0.06);
        border: 1px solid $border-gray;
        color: $main-black;
    }

    .background.transparent {
        background-color: $background;
        border: 1px solid $border-gray;
        color: $main-black;
    }

    .big {
        height: 40px;
    }

    .dropdown-item-tag {
        padding: 0.5rem 0.9rem;
        span {
            font-size: 0.8rem;
        }
    }
}
