#ReviewedBy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
  padding: 2px 5px 2px 5px;

  .rejected {
    color: #BF2600
  }

  .approved{
    color: #00A656;
  }

  .icon {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
  }

  .text {
    margin-left: 5px;
    font-size: 14px;
  }
}

.rejected.background {
  background-color: #FFEBE6;
}

.approved.background {
  background-color: #E3FCEF;
}