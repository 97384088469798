@import './variables.scss';

$side-menu-width: 4rem;
$expanded-side-menu-width: 15rem;
$main-dark-gray: #6c6c6c;
$border-gray: #e8e8e8;
$main-gray: #a5a5a5;


h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  padding: 0;
  margin: 0;
}

#ChargeStatus {
  p {
    margin: 0 0 1em;
  }
}

#ChargePayments {
  @media only screen and (min-width: 1200px) {
    .ui.container {
      width: unset;
    }
  }
}

.menu.transition.visible {
  max-height: 350px;
}

.generic-button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  border-radius: 200px;


  &>.wrapper {
    display: flex;
    flex-shrink: 0;
    height: 43px;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;

    &.white {
      background-color: '#FFF';
      border: 1px solid rgba(0, 0, 0, 0.16);
      box-sizing: border-box;
      border-radius: 200px;
    }

    &.light {
      background-color: '#fff';
      background-color: rgba(8, 79, 255, 0.04);
      border: 1px solid rgba(8, 79, 255, 0.04);
      box-sizing: border-box;
      border-radius: 200px;
    }

    &.icon {
      border-radius: 200px;
      padding-right: unset;
      padding-left: unset;
      width: 43px;
      justify-content: center;
    }

    &>.icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 1.25rem;
      font-weight: 300;
      min-width: 1.5rem;

      &.angle {
        font-weight: 1rem;
      }
    }
  }

}

.main-layout {
  display: flex;

  input,
  select,
  textarea {
    padding: 11px 16px;
    border: 1px solid $border-gray;
    box-sizing: border-box;
    font-size: 15px;
  }

  input,
  select {
    line-height: 19px;
  }

  input,
  textarea {
    border-radius: 4px;

    &:focus {
      outline: none;
    }

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: #d2d2d2;
    }
  }

  input.search,
  select {
    border-radius: 200px;

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: $main-gray;
    }
  }

  .searchMenu {
    position: relative;

    &>.icon {
      position: absolute;
      left: 16px;
      display: flex;
      align-items: center;
      height: 100%;
      z-index: 1;
    }

    @media only screen and (max-width: 767px) {
      &>input {
        font-size: 16px;

        &.fillAllWidth {
          width: 100%;
        }

        width: 200px;
        padding-left: 42px;
      }
    }

    @media only screen and (min-width: 768px) {
      &>input {
        font-size: 16px;

        &.fillAllWidth {
          width: 100%;
        }

        width: 250px;
        padding-left: 42px;
      }
    }

  }

  .dropdownFilter {
    padding: 10px 16px;
    height: 43px;
    border: 1px solid $border-gray;
    box-sizing: border-box;
    border-radius: 200px;
    align-items: center;
    display: flex;

    &>.menu {
      max-height: 300px;
      overflow-y: auto;
    }

    &.dropdown-borded {
      color: $main-black;
      font-weight: 500;
      font-size: 15px;
    }

    &:not(.button)>.default.text {
      font-size: 15px;
      line-height: 19px;
      color: $main-black;
    }

    i.icon {
      margin: 0 0 0.219rem 0.35rem;
      float: right;
      height: 92%;
    }



    &.searchable.active {
      width: 220px !important;

      >.menu.transition.visible {
        overflow-y: overlay;


        >.item {
          max-width: 220px;

          text-overflow: ellipsis;
          overflow-x: hidden;
        }

        >.item:hover {
          text-overflow: clip;
          white-space: normal;
          word-break: break-word;
        }
      }

      >input.search {
        padding-left: 40px !important;
      }

      i.icon {
        margin: 0 0.40rem 0.25rem 0;
        float: left;
      }
    }

    &.searchable:not(.active)>input.search {
      padding-right: 36px !important;
    }
  }

  /* Table */
  & .global-table {
    width: 100%;

    &>.table-action-bar {
      margin-bottom: 24px;
    }

    &>.table-header,
    &>.table-row {
      display: grid;
      grid-auto-flow: column;
      column-gap: 24px;
      align-items: center;
      padding: 16px;
      font-size: 16px;
      line-height: 19px;
      box-shadow: inset 0px -1px 0px $border-gray;
    }

    &>.table-header {
      color: $main-dark-gray;
      box-shadow: none;
      font-size: 16px;
    }

    &>.loading {
      margin: 80px 0;
    }
  }

  /* Table Action Bar */
  & .table-action-bar {
    font-size: 16px;

    &>.actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      &>.data-render-actions {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
    }

    &>.filter-tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;

      &>.global-tag-groups {
        margin: 8px 8px 0 0;
      }
    }
  }

  /* Tag */
  & .global-tag-groups {
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 8px;
    border: 1px solid $border-gray;
    box-sizing: border-box;
    border-radius: 200px;

    &>span {
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      color: $main-black;
      white-space: nowrap;
    }

    &>.icon {
      margin: 0 0 3px 4px;
      --fa-primary-color: white;
      cursor: pointer;
    }
  }

  & .organization-floating-menu {
    background-color: #FFF;
    min-width: 300px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1;
    position: absolute !important;

    &>.item {
      padding: 12px 15px !important;
    }

    .avatar {
      width: 2rem;
      height: 2rem;
      max-Height: unset;
    }
  }

  &>.side-menu {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 8px 0 16px;
    background-color: $background;
    z-index: 10;
    height: 100vh;
    width: $side-menu-width;
    flex-shrink: 0;
    transition: 200ms ease;

    &>.inner-side-menu {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;

      .side-menu-item {
        position: relative;
        display: flex;
        align-items: center;
        height: 3rem;
        justify-content: start;
        cursor: pointer;
        user-select: none;
        border-radius: 200px;

        &:not(.logo) {
          padding: 0 .75rem;

          &:not(.noHover) {
            &:hover {
              background-color: rgba(0, 0, 0, 0.02);
            }
          }
        }

        &.colorful {
          background-color: #084FFF;

          &:not(.logo) {
            padding: 0 .75rem;

            &:hover {
              background-color: #084FFF;
              opacity: 0.9;
            }
          }
        }

        &.noHover {
          cursor: default;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.logo {
          margin-left: .6rem;

          &>.classapp-name {
            margin-left: .325rem;
            width: 0;
          }
        }

        &>.wrapper {
          display: flex;
          flex-shrink: 0;
          align-items: center;

          &>.icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            font-size: 1.25rem;
            font-weight: 300;
            min-width: 1.5rem;

            &.angle {
              font-weight: 1rem;
            }
          }
        }

        &.trigger {
          width: 3rem;
        }

        &.active {
          background: #F9FAFB;

          &>.wrapper>.icon,
          >span {
            font-weight: 700;
            color: $main-blue;
          }
        }

        &>.item-icon {
          margin-left: 3px;
          flex-shrink: 0;
        }

        &>.description {
          display: none;
          user-select: none;
          position: absolute;
          left: 120%;
          top: 50%;
          transform: translateY(-50%);
          padding: 4px 8px;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
          border-radius: 200px;
          background-color: $background;
          z-index: 2;
          white-space: nowrap;
        }

        &:hover>.description {
          display: block;
        }
      }

      &>.side-menu-organization-bottom {
        margin-top: auto;
        width: 100%;
        position: relative;

        .side-menu-item {
          padding: 0 .5rem;

          &>.item-icon {
            margin: 0;
          }
        }
      }

      .organization-floating-menu {
        bottom: 20px;
        left: 120%;
        max-height: 400px;

        .avatar {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    &.expanded {
      width: $expanded-side-menu-width;

      &>.inner-side-menu {
        .side-menu-item {
          &>.wrapper>span {
            margin-left: 1rem;
            line-height: 1.7rem;
            font-size: 16px;
          }

          &>.description {
            left: 105%;
          }

          &.logo {
            justify-content: flex-start;

            &>.classapp-name {
              width: auto;
            }
          }
        }

        &>.side-menu-organization-bottom {
          .side-menu-item.item {
            .ui.avatar.image {
              margin-right: 1rem;
            }
          }

          .organization-floating-menu {
            left: 105%;
          }
        }
      }
    }
  }

  &>.page {
    height: 100vh;
    overflow-y: auto;
    flex: 1;
    outline: none;

    &>.main-header {
      &>.main-content {
        padding: 3.5rem 5rem 2rem;

        @media (max-width: $small-screen-width) {
          padding: 3.5rem 1.5rem 2.5rem;
        }

        &>.breadcrumb {
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;

          &>*:not(:last-child) {
            margin-right: .5rem;
          }
        }

        &>.breadcrumb>*,
        &>.back-button {
          font-size: .875rem;
          color: $main-black;
        }

        &>.content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 3rem;
          flex-wrap: wrap;

          &>* {
            margin-bottom: 0.9rem;
          }

          &>.left {
            margin-right: 1rem;

            h1 {
              font-weight: 700;
              font-size: 2rem;
              letter-spacing: -0.02em;
            }

            &>div>.title-wrapper {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              &>h1 {
                margin: 0;
                word-break: break-word;
              }
            }

            &>.description {
              margin-top: 1rem;
            }
          }

          &>.right {
            display: flex;
            flex-wrap: wrap;

            &>*:not(:last-child) {
              margin-right: 1rem;
            }

            &>* {
              margin-bottom: 0.2rem;
            }

            .modal {
              p {
                white-space: normal;
              }
            }
          }
        }

        &>.tag-list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 1rem;

          &>* {
            margin: .5rem .5rem 0 0;
          }
        }
      }
    }

    &>.content {
      width: 100%;
      padding: 0 5rem 3.5rem;

      &.no-header {
        padding-top: 56px;
      }

      @media (max-width: $small-screen-width) {
        & {
          padding: 0 1.5rem 3.5rem;
        }
      }
    }
  }
}

.hoverLink:hover {
  background-color: #FAFAFA;
  font-weight: bold,
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    display: none;
  }
}

.suggestion-dont-break-line {
  input {
    overflow: scroll;
    width: unset !important;
  }
}