.ui.tiny.label.image.round {

    background-color: #fff;
    height: 40px;
    &.round-corners {
        height: unset;
    }
    border-radius: 200px;
    padding: 8px 16px 8px 8px;
    color: #000;
    font-weight: 400;
    font-size: 0.875rem;

    div {
        vertical-align: top;
        height: 2em;
        margin: 0;
        margin-right: 8px;
    }

    svg.clsp-svg {
        border-radius: 500rem;
    }

    img {
        border-radius: 500rem; 
        width: 28px !important;
        height: 28px !important;
        margin-left: 0;
        margin-right: 8px;
    }

    i {
        opacity: 1;
    }

    &.align-icon {
        i {
            margin-top: 4px;
        }
    }

    &.no-close-button {
        padding: 8px;
    }

}

.ui.scrolling.dropdown.no-search-padding {
    input {
        padding: inherit !important;
    }

    &.full-screen {
        div.menu.transition.visible {
            &::-webkit-scrollbar {
                display: none;
              }
        }
        input {
            padding-left: 12px !important;
            padding-right: 12px !important;
        }

        div.default.text {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
    
}
#EntityRecipients {

    .EntityRecipientsContent{
        &::-webkit-scrollbar {
            display: none;
          }
    }

    .ui.vertical.menu {
        margin: 0;
        margin-top: 6px;
        div.item {
            margin: 0px;
            padding: 9px 16px;
            display: flex;
            align-items: center;
            span {
                i {
                    width: 24px;
                    font-size: 24px;
                }
            }
        }
        div.entity-item {
            a {
                margin: 0px;
                padding: 9px 16px;
                span {
                    i {
                        width: 24px;
                        font-size: 24px; 
                    }
                }
            }
        }
    }
}

.searchRecipients {
    position: relative;

    .icon {
      position: absolute;
      left: 16px;
      display: flex;
      align-items: center;
      height: 100%;
      z-index: 1;
    }
  
    input.search {

      font-size: 16px !important;
      padding-left: 2.6rem !important;
      border-radius: 200px !important;
      height: 48px;
        &.full-screen {
            border-radius: 0px !important;
            padding-left: 12px !important;
            height: 20px;
            display: flex;
            border: none !important;
        }
      
      &.fillAllWidth {
        width: 100% ;
      }
      width: 134px;
      padding-left: 42px;
    }
  }

  #RecipientsModal {
    height: 70%;
    position: fixed !important;
  }