#RadioButtonList {
  @import "../variables.scss";

  .title {
    font-size: 18px;
    font-weight: bold;
    font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    line-height: 22.77px;
    letter-spacing: -0.02em;

    margin-bottom: 16px;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: none;
    margin-bottom: 8px;
  }

  .radio-label {
    position: relative;
    display: inline-flex;
    align-items: center;

    padding: 12px 16px;
    border-radius: 8px;

    cursor: pointer;
  }

  .selected {
    background-color: #F2F7FF;
  }

  .label-text {
    margin-left: 24px;

    font-size: 16px;
    font-weight: 450;
    font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    line-height: 22.4px;
  }

  .checkmark {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #CCCCCC;
  }

  .radio-button {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .radio-button:checked~.checkmark {
    background-color: #0069FF;
    border-color: #0069FF;
  }

  .checkmark:after {
    content: "";
    position: relative;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-button:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }
}