#ReportResultForm {
    .ui.segment {
        border-radius: 0.5rem;
    }
    .ui.fluid.two.item.menu {
        border-radius: 0.5rem;
    }
  }

.no-padding {
    padding: 0px !important
}