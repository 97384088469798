.snackbar-not-visible {
    display: none;
}

.snackbar-visible {
    display: block;
}

.snackbar-wrapper-sucess {
    background-color: #e3fcef !important;
}

.snackbar-wrapper-error {
    background-color: #fce3e3 !important;
}

.snackbar-wrapper-info {
    background-color: #e3ebfc !important;
}

.snackbar-wrapper {
    top: 0;
    display: flex;
    left: 50% !important;
    will-change: transform;
    animation: snackbar-animation ease 4s;
    position: fixed;
    z-index: 1004;
    box-shadow: none !important;
    height: 56px !important;

    &.big-height {
        height: 90px !important;
    }

    &.dismiss-after3000 {
        animation: snackbar-animation ease 4s;
    }

    &.dismiss-after4000 {
        animation: snackbar-animation ease 5s;
    }

    &.dismiss-after5000 {
        animation: snackbar-animation ease 6s;
    }

    &.dismiss-after6000 {
        animation: snackbar-animation ease 7s;
    }

    &.dismiss-after7000 {
        animation: snackbar-animation ease 8s;
    }

    &.dismiss-after10000 {
        animation: snackbar-animation ease 11s;
    }

    .notification-bar-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media only screen and (min-width: 767px) {
        #Snackbar {
            display: flex;
            align-items: center;

            .snackbar-icon {
                margin-top: 0.2rem;
            }

            .snackbar-message {
                color: #000;
            }

            .snackbar-action {
                margin-top: 0.2rem;
            }
        }

    }

    @media only screen and (max-width: 767px) {
        #Snackbar {
            width: 20rem;
            display: flex;
            align-items: center;

            .snackbar-icon {
                margin-top: 0.2rem;
            }

            .snackbar-message {
                width: 100%;
                color: #000;
            }

            .snackbar-action {
                margin-top: 0.2rem;
            }
        }
    }
}

@keyframes snackbar-animation {
    0% {
        transform: translate(-50%, -4rem);
    }

    20% {
        transform: translate(-50%, 2rem);
    }

    80% {
        transform: translate(-50%, 2rem);
    }

    100% {
        transform: translate(-50%, -4rem);
    }
}