#EntityPicture,
#DeletePicture {

  &.modal {
    max-width: 540px;
    border-radius: 12px;

    .header {
      border-radius: 12px !important;
    }

    .actions {
      border-radius: 12px;
    }
  }

  .content {

    .ui.circular.image {
      width: 177px;
      height: 177px;
      margin: auto;
    }

    p {
      margin-bottom: 8px;
    }

    @media only screen and (max-width: 767px) {
      .dropzone {
        position:relative;
        width: 100%;
        height: 225px;
        margin: auto;

        border-radius: 32px;
        border: 1px dashed rgba(0, 0, 0, 0.20);
  
        background-color: #FFFFFF;
        color: #000000;
  
        > .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-left: auto;
          margin-right: auto;
          width: 48px;
          height: 48px;
          margin: 0;
          padding-top: 15px;
          font-size: 24px;
          font-weight: 300;
        }
  
        span {
          position: relative;
          top: 118.5px;
  
          font-weight: 300;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      .dropzone {
        position:relative;
        width: 492px;
        height: 225px;
        margin: auto;
  
        border-radius: 32px;
        border: 1px dashed rgba(0, 0, 0, 0.20);
  
        background-color: #FFFFFF;
        color: #000000;
  
        > .icon {
          position: absolute;
          top: 75px;
          left: 222px;
  
          width: 48px;
          height: 48px;
          margin: 0;
  
          font-size: 24px;
          font-weight: 300;
          text-align: center;
        }
  
        span {
          position: relative;
          top: 118.5px;
  
          font-weight: 300;
        }
      }
    }

    .dropzone:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
