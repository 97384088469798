@font-face {
    font-family: 'Circular Std';
    font-weight: 900;
    font-style: italic;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-BlackItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 900;
    font-style: normal;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-Black.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 700;
    font-style: normal;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 700;
    font-style: italic;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-BoldItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 400;
    font-style: normal;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 400;
    font-style: italic;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-MediumItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 300;
    font-style: normal;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-Book.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular Std';
    font-weight: 300;
    font-style: italic;
    src: url('https://assets.classapp.co/semantic-16px/fonts/CircularStd-BookItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Allison';
    font-weight: 1 1000;
    font-display: block;
    src: url('https://assets.classapp.co/semantic-16px/fonts/Allison-Regular.woff2') format('woff2');
}
