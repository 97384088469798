#MultipleDropdown {
    @import "../variables.scss";

    .ui.fluid.multiple.search.selection.dropdown {
        padding: 24px;
        padding-top: 12px;

        a.ui.label {
            background-color: #ffffff !important;
            border: 1px solid #d6d6d6;
            color: #000000;
            box-sizing: border-box;
            border-radius: 200px;
            -webkit-box-shadow: unset;
            box-shadow: unset;
            margin-right: 12px;
            margin-top: 12px;
            font-weight: normal;
        }

        i.dropdown-icon {
            position: absolute;
            right: 5px;
        }
    }

    .error-warning {
        margin-top: 0.5rem;
        color: $main-red;

        & > .icon {
            margin-right: 0.5rem;
            font-weight: 900;
        }
    }

    .dropdown-error {
        border: 1px solid $main-red;

        i.dropdown-icon {
            color: $main-red;
        }
    }
}
.form-dropdown {

    &.formresult {
        .dropdown {
            height: fit-content;
            word-break: break-word;
        }
    }

    label {
        font-size: 16px !important;
        margin-bottom: 12px !important;
    }

    .dropdown {
        font-size: 16px;
        height: 47px;
        padding: 10px 16px !important;
        border-radius: 8px !important;
        align-items: center;
        display: flex !important;
        justify-content: space-between;
        
        i.icon {
            margin: 0 0 0 0.60rem;
        }
        
        i.icon.inside_dropdown {
            margin: 0 6px 0 0rem;
        }

        &.visible {
            border-radius: 8px 8px 0 0 !important;
            border-color: rgba(34,36,38,.35);

            &.upward.selection {
                border-radius: 0 0 8px 8px !important;
            }
        }
      
        .menu.transition.visible {
            border-color: rgba(34,36,38,.35) !important;
            border-radius: 0 0 8px 8px !important;
    
            &:hover {
                border-color: rgba(34,36,38,.35) !important;
            }
        }

        &.upward .menu.transition.visible {
            border-radius: 8px 8px 0 0 !important;
        }
    }
}
