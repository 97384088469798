#ReviewStatusTooltip {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;


  .text.approved {
    color: #00A656;
  }
  .text.sendLater {
    color: #FFFFFF;
  }

  .approved.inReview {
    color: #616161;
  }

  .tooltip-icon {
    font-weight: 300;
    font-size: 24px;
    display: flex;
    align-items: center;
    margin: 0 20px;
  }

  .tooltip-icon.approved {
    color: #00A656;
  }

  .tooltip-icon.inReview {
    color: #616161;
  }
  .tooltip-icon.sendLater {
    color: #FFFFFF;
  }
  .close-icon.sendLater {
    color: rgba(0, 0, 0, 0.6);
  }
}

.approved {
  background-color: #E3FCEF;
}

.inReview {
  background-color: #E5E5E5;
}
.sendLater{
  background-color: #FBB13C;
  justify-content: space-between !important; 
}
.tooltip-segment {
  padding: 0 !important;
}