.error-warning {
    @import "../variables.scss";
    
    margin-top: 6px;
    margin-bottom: 12px;
    color: $main-red;

    & > .icon {
        margin-right: 0.5rem;
        font-weight: 900;
    }
}