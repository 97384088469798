#ConciergeAdd,
#EntityAdd,
#EntityEdit,
#ChannelAdd,
#ChannelEdit,
#IntegrationForm,
#ListEntity,
#AuthProspect,
#AuthSignUp,
#SelectUser,
#SelectGroup,
#AddressAdd,
#DeletePicture,
#AddGroup,
#LabelAdd,
#LabelForm,
#LabelEdit,
#CommitmentForm,
#FormResultForm,
#SurveyForm,
#ChargeForm,
#ReportFieldAdd,
#FormFieldAdd,
#IntegrationAdminForm,
#IntegrationAdminAdd,
#IntegrationAdminEdit,
#IntegrationNode,
#ModuleAdd,
#ModuleEdit,
#ModuleForm,
#ContractAdd,
#ContractEdit,
#ContractForm,
#UserHome,
#ExportModal {
    @import "../variables.scss";

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="email"],
    input[type="password"],
    input[type="search"],
    input[type="text"],
    input[type="number"],
    select,
    textarea {
        padding: 1em;
        border: 0;
        //   box-shadow: 0 0 0 1px $border-gray inset;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        border-radius: 0.5rem;
        font-size: 1rem;
        //   line-height: 1.1rem;
        min-height: 3rem;
        transition: ease-in-out 0.15s;
        max-width: 100%;

        &:disabled {
            border: none;
            background: rgba(0, 0, 0, 0.04);
            color: rgba(0, 0, 0, 0.6);
        }

        &:focus {
            // border: 2px solid #0069FF;
            // box-shadow: 0px 0px 0px 3px rgba(0, 105, 255, 0.16);
            outline: 0;
        }
    }

    .input-component {
        max-width: 100%;

        &>.search-input {
            position: relative;

            &>.icon {
                position: absolute;
                height: 100%;
                z-index: 1;

                &.search {
                    left: 1rem;
                    top: 0.9rem;
                }

                &.times {
                    right: 1em;
                    font-weight: 900;
                    color: rgba(0, 0, 0, 0.4);
                    cursor: pointer;
                }
            }

            &>input {
                width: 100%;
                padding: 1rem 2.5rem;
                border-radius: 200px;
            }
        }

        &>.text-input {
            position: relative;
            display: flex;
            align-items: center;

            &.percentage {
                width: 7rem;

                input {
                    padding-right: 2.1rem;
                }

                &>input:disabled {
                    background-color: rgba(0, 0, 0, 0.04);
                    color: $main-gray;
                }
            }

            &>.icon {
                position: absolute;
                height: auto;
                z-index: 1;
            }

            &>input {
                width: 100%;
                padding: 1rem 1rem;
            }

            &>input.padding-right {
                width: 100%;
                padding-right: 2rem;
            }

            &>input.padding-left {
                width: 100%;
                padding-left: 2rem;
            }

            &>.icon.align-left {
                left: 1rem;
            }

            &>.icon.align-right {
                right: 1rem;
            }
        }

        &>.radio-input,
        .checkbox-input {
            position: relative;
            display: flex;
            align-items: center;
            padding: 1rem 1rem 1rem 3.25rem;
            border-radius: 200px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &>input {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                // width: 97%;
            }

            &:not(:disabled) {
                cursor: pointer;
            }

            &>i.icon {
                height: auto;
                width: auto;
            }

            &>i.icon.selected,
            &>i.icon.unselected {
                position: absolute;
                left: 1rem;
                color: rgba(0, 0, 0, 0.16);
                font-size: 1.5rem;
                margin-right: 0.75rem;
            }

            &>i.icon.selected {
                color: $main-blue;
                font-weight: 900;
            }

            &.selected {
                background: $background-blue;
            }

            &.disabled>i.icon.selected {
                color: $disabled-blue;
            }
        }

        &>textarea {
            width: 100%;
            min-height: 5rem !important;
            resize: none !important;
            height: 5rem !important;

            &.big-height {
                height: 8rem !important;
            }
        }

        &>.radio-input.big {
            width: 100%;
            padding: 1rem;

            &>input {
                width: 95%;
                height: 70%;
            }

            &>.icon-border {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                min-width: 3rem;
                height: 3rem;
                margin-right: 1rem;
                border: 1px solid $border-gray;
                box-sizing: border-box;
                border-radius: 200px;

                &>i.icon {
                    font-size: 1.25rem;
                    color: $main-blue;
                }
            }

            &>.content {
                width: 100%;
                margin-right: 3rem;

                &>.description {
                    color: $main-gray;
                }
            }

            &>i.icon.selected,
            &>i.icon.unselected {
                right: 1rem;
                left: unset;
                margin-left: 1rem;
                margin-right: 0;
            }

            &.selected {
                &>.icon-border {
                    border: 0;
                }
            }
        }

        &.calendar-input>.text-input {
            cursor: pointer;

            >input {
                cursor: pointer;
                z-index: 1;
                background: transparent;

                &:hover {
                    border-color: rgba(34, 36, 38, .35);
                }
            }

            >i {
                z-index: 0;
            }
        }

        &>.error-warning {
            margin-top: 4px;
            margin-bottom: 12px;
            color: $main-red;

            &>.icon {
                margin-right: 0.5rem;
                font-weight: 900;
            }
        }
    }
}
