#PermissionModal {
  @import "../variables.scss";

  width: 571px;

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 36px;
  }

  #approval-permissions-cancel-button {
    font-size: 16px;
    color: $main-black;
    text-decoration-line: underline;
    font-weight: 450;
    font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

    background: transparent;

    border-radius: 200px;
    border: none;
    cursor: pointer;
  }

  #approval-permissions-confirm-button {
    padding: 17px 24px;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 450;
    font-family: Circular, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

    background-color: #0069FF;

    border-radius: 200px;
    border: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 767px) {
    width: 95%;
  }
}