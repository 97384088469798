.beta-modal {
  border-radius: 12px !important;

  @import '../variables.scss';

  @media only screen and (max-width: 767px) {
    .ui.modal>.actions {
      padding: 1rem 1rem !important;
    }
  }

  @media only screen and (max-width: 889px) {
    .ql-toolbar {
      margin-bottom: 40px;
    }
  }

  .content:not(.no-padding) {
    background-color: #ffffff;
    padding: 16px 24px !important;
    border-radius: 12px !important;

    img {
      max-width: 100%;
    }
  }

  >.header:not(.ui) {
    font-size: 1.28571429em;
    line-height: 1em;
  }

  >.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #FFF !important;
    border-top: none !important;
    padding: 1rem 0.7rem !important;

    >.button {
      margin-right: 0.75rem;
    }
  }

  .close {
    font-weight: bold;
  }

  .close.inside {
    cursor: pointer;
    position: absolute;
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0, 0, 0, 0.87);
    z-index: 1;
    opacity: 0.8;
    font-size: 1.25em;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.25rem 0 0 0;
  }

  .modal-actions-footer {
    display: flex;

    &.space-between {
      justify-content: space-between !important;
    }
  }

  // .header {
  //     font-size: 1.43rem;
  //     padding: 20px 24px !important;
  //     border-bottom: none !important;
  // }

  .header:not(.entityItem):not(.MessageItem) {
    font-size: 1.43rem;
    padding: 20px 24px !important;
    border-bottom: none !important;
  }

  .header-items {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ui.button .icon {
      font-size: 24px !important;
      font-weight: 300 !important;
    }
  }

  .ui.button.big-icon .icon {
    font-size: 16px;
    font-weight: 300;
  }

  .dimmer {
    overflow: auto;
  }

  &.full-screen {
    border-radius: 0 !important;
    top: 0 !important;
    left: 0 !important;
    height: 100vh;
    max-height: unset !important;
    width: 100%;
    margin: 0 0 0 !important;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.fix-header {
    overflow-y: hidden;

    .content {
      padding-bottom: 66px !important;
      max-height: 100% !important;
      overflow-y: auto;
    }
  }

  &.toast {
    top: unset;
    bottom: 0;
    width: 100% !important;
    margin: 0 0 0 !important;
    left: 0;
    right: 0;
    border-radius: 4px 4px 0px 0px !important;

    ::-webkit-scrollbar {
      display: none;
    }

    .content {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .send-later,
    .options-toast {
      button.ui.button {
        background: transparent !important;
        color: #000000 !important;
        font-weight: 400 !important;
        height: 46px;
        width: 100%;
        padding: 10px !important;
        display: flex;
        justify-content: space-between;
        align-items: last baseline;
        margin-bottom: 8px;

        .icon {
          font-size: 20px;
          font-weight: 300;
        }

        .date {
          font-size: 14px;
          margin-left: 12px;
          color: #084FFF;
          font-weight: 400;
        }
      }
    }

    .actions .button {
      height: 40px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      &.transparent {
        border: none !important;
        color: $main-blue  !important;
      }
    }
  }

  &.no-content-padding {
    .content {
      padding: 0 !important;
    }
  }

  &#MessageAdd,
  &#MessageEdit,
  &#MessageToApproveEdit,
  &#DraftEdit {
    .content {
      padding: 0 !important;

      &.MessageItem {
        padding: 16px !important;
      }
    }

    .header-items .ui.button {
      margin-right: 18px !important;
    }

    .ui.basic.button.round.black-content.padding-center .icon {
      display: table;
      font-weight: 300;
      font-size: 16px;
    }
  }

  &#CommitmentDateToast,
  &#EventDateToast,
  &#CommitmentConfirmDateToast,
  &#FormDateLimitToast,
  &#SurveyDateLimitToast,
  &#ChargeExpirationDateToast {
    .content {
      display: flex;
      justify-content: center;
    }
  }

  &#PopUp {
    &.web {
      width: 450px;
    }
  }
}
.featureAnnouncementModal{
  width: 427px !important;
}

.modals.dimmer {
  .ui.scrolling.modal.beta-modal.full-screen {
    margin: 0 0 0 !important;
    width: 100%;

    &:not(.fix-header) {
      div {
        &.content {
          padding: 0 !important;
          max-height: unset;
        }

        &.header {
          padding: 0px !important;
        }
      }
    }

    &.fix-header {
      div {
        &.content {
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-top: 0 !important;
          padding-bottom: 66px;
          max-height: unset;
        }

        &.header {
          padding: 0px !important;
        }
      }
    }

  }

  .ui.scrolling.modal.beta-modal.full-screen.margin-bottom {
    div {
      &.content {
        margin-bottom: 72px;
      }
    }
  }
}

.ui.modals.dimmer {
  overflow: auto;
}

body.hidden-modal-scroll {
  .ui.modals.dimmer {
    overflow: hidden;

    .beta-modal {
      max-height: calc(95vh) !important;

      .content {
        max-height: calc(95vh) !important;
        padding-bottom: 100px !important;
      }
    }
  }
}

.ui.segment.labels {
  border: 0;
  box-shadow: none;

  &.mobile {
    max-height: 50vh;
    overflow: scroll;
  }
}

.header-items {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ui.button .icon {
    font-size: 24px !important;
    font-weight: 300 !important;
  }
}