#ArrivedConfirmSubmitModal,
#ChatNews,
#ArrivedIntro,
#ArrivedForm,
#ConciergeAdd,
#DeleteConciergeModal,
#EntityAdd,
#EntityEdit,
#AuthProspect,
#CreatedPasswordModal,
#AuthCreatePassword,
#AuthSignUp,
#AuthLogin,
#CodeChecker,
#ChannelAdd,
#ChannelEdit,
#LabelAdd,
#LabelForm,
#LabelEdit,
#IntegrationForm,
#ListEntity,
#SelectUser,
#SelectGroup,
#EntityPicture,
#AddressAdd,
#SelectPermissions,
#DeletePicture,
#AddGroup,
#RoleAdd,
#EntityPatch,
#ErrorModal,
#CheckSurveyNameModal,
#ShouldShowNotificationOptions,
#MessageAdd,
#MessageFiles,
#DraftEdit,
#MessageEdit,
#MessageToApproveEdit,
#FileToast,
#MessageCloseModal,
#ReportCloseModal,
#PendingUserModal,
#ConfirmSignatureModal,
#LabelModal,
#recipientAttachmentForm,
#reporResultModal,
#DatePicker,
#OptionsToast,
#PaymentFilterInterval,
#PopUp,
#reportAttachmentForm,
#commitmentAttachmentForm,
#surveyAttachmentForm,
#chargeAttachmentForm,
#formAttachmentForm,
#CommitmentEdit,
#SurveyEdit,
#MessageSignatures,
#InfoModal,
#BankConfirmationModal,
#EntityExternalAccessHeader,
#ExternalAccessEntityContent,
#PaymentsDashboard,
#ReportFieldAdd,
#FormFieldAdd,
#MomentAdd,
#MomentEdit,
#ExportOrganization,
#IntegrationAdminForm,
#IntegrationAdminAdd,
#IntegrationAdminEdit,
#IntegrationOrganization,
#OrganizationEntities,
#ModuleAdd,
#ModuleEdit,
#ModuleForm,
#ContractAdd,
#ContractEdit,
#ContractForm,
#ExportModal,
#AddAccountModal {

  @import '../variables.scss';

  .button {
    min-width: 2.625rem;
    min-height: 2.625rem;
    padding: 0.788rem 1.313rem;
    color: $background;
    background: $main-blue;
    border: 0;
    border-radius: 0.438rem;
    outline: none;
    font-family: Circular, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.213rem;
    cursor: pointer;

    &.black-content {
      color: #000000 !important;

      i.icon.icon {
        color: #000000;
      }

      span {
        color: #000000;
      }
    }

    &:hover,
    &:focus {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), $main-blue;
    }

    &:disabled {
      background: $disabled-blue;
      cursor: default;
    }

    &.padding-icon {
      padding: 0.78571429em 0.78571429em;
      margin: 0 !important;
    }

    &.padding-center {
      padding: 0.788rem 1rem;
      margin: 0 8px 0 0;
    }

    &.no-border {
      box-shadow: none;

      i.icon.icon {
        margin-right: 4px;
      }
    }

    &.margin-icons {
      i.icon.icon {
        margin-right: 16px;
      }

      div {
        justify-content: left !important;
      }
    }

    &.increase-font-size {
      font-size: 1.143rem !important;
    }

    &.gray {
      color: white;
      background: rgba(153, 153, 153, .5);
    }

    &.green {
      background: $main-green;
    }

    &.blue {
      color: $main-blue;
      background: $background;
      border: 1px solid $main-blue;

      &:disabled {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &.red {
      background: $main-red;

      &.secondary {
        color: $main-red;
        background: $background;
        border: 1px solid $main-red;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background: rgba(0, 0, 0, 0.06);
        }
      }

      &:hover,
      &:focus {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), $main-red;
      }

      &:disabled {
        background: $disabled-red;
      }

      &.transparent {
        color: $main-red;
        background: $background;
        border: 1px solid $main-red;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background: rgba(0, 0, 0, 0.06);
        }

        &:disabled {
          color: $disabled-red;
        }
      }
    }

    &.transparent {
      color: $main-black;
      background: $background;
      border: 1px solid $border-gray;

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        background: rgba(0, 0, 0, 0.06);
      }

      &:disabled {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &.no-margin-icon {
      min-width: 0;
      min-height: 0;

      &.icon {
        i.icon {
          margin-right: 0;
        }
      }
    }

    &.paywall {
      &.icon-text {
        color: #A5A5A5;

        i.icon.icon {
          margin-right: .5rem;
        }
      }
    }

    &.round {
      border-radius: 200px;
    }

    &.full-width {
      width: 100%;
    }

    &.icon-text>i.icon.icon {
      margin-right: .5rem;
    }

    &.icon,
    &.loading {
      width: 3rem;
      padding: 0;
    }

    &.link {
      display: flex;
      align-items: center;
      justify-content: center;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}

#MessageAdd,
#DraftEdit,
#MessageEdit,
#MessageToApproveEdit,
#FileToast {
  .button {
    font-size: 0.875rem;

    &.message-label {
      min-height: 0px;
      margin: auto;
      border-radius: 200px;
      height: 32px;
      align-items: center;
      padding: 0px 16px;
      white-space: nowrap;

      display: flex;
      flex-direction: row-reverse;

      .icon {
        display: inline-table;
        font-weight: 400;
        margin-right: -3px;
        margin-left: 6px;
      }

      &.empty {
        background-color: white;
        border: 1px solid #00000029;
        color: black;
      }
    }
  }
}
