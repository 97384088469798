.listEntityItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 8px 16px;

  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .itemInfo {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }
  }

  &+.listEntityItem {
    margin-top: 1px;
  }
}

#disabledButtonWithHover.button {
  cursor: unset;
  user-select: none;

  &:hover,
  &:focus {
    background: rgb(194, 219, 255);
  }
}