.nestedCheckbox {
  $checked-bg: #F2F7FF;

  .icon {
    cursor: pointer;
  }

  .mainItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px 16px;

    &.border-bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .checkbox {
        margin-right: 16px;
      }
    }

    &.checked>label {
      background-color: $checked-bg;
    }
  }

  .subItem {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 50px;

    div {
      margin-top: 0;
    }

    &.checked {
      background-color: $checked-bg;
    }

    &.border-top {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &.border-bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &+.nestedCheckbox {
    margin-top: 1px;
  }
}