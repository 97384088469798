
.ui.pagination.secondary {

    &.beta-paginator.menu {
        height: 48px;
        a.item {
            background: none !important;
            align-items: center;
            justify-content: center;
            height: 48px;
            width: 48px;
            margin-right: 0px;
            margin-left: 0px;
        }
        a.item:hover {
            color: #084FFF
        }
        a.disabled.icon.item{
            color: rgba(0, 0, 0, 0.6);
        }
        a.active.item {
            background: #F2F7FF !important;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
        }
    }

    &.right.floated.beta-paginator.menu{
        
        .ui.compact.selection.upward.dropdown {
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.16);
            box-sizing: border-box;
            border-radius: 2000px;
            align-items: center !important;
            
            i.dropdown.icon {
                top: unset;
                align-self: center;
            }
        }
    }
}