$background: #fff;

$main-blue: #084FFF;
$background-blue: #F2F7FF;
$disabled-blue: #c2dbff;

$main-green: #00a656;
$background-green: #e3fcef;

$main-yellow: #efc93d;
$background-yellow: #fff7ed;

$main-red: #bf2600;
$background-red: #ffebe6;
$disabled-red: #E5A899;

$main-gray: rgba(0, 0, 0, 0.6);
$background-gray: #fafafa;
$main-dark-gray: #6c6c6c;

$main-black: #000;

$border-gray: rgba(0, 0, 0, 0.16);

$small-screen-width: 800px
